import { TextEditor, TextEditorRef } from "@dono-business/shared/components/texteditor";
import { CreateGiftFormFieldsModel } from "@dono-business/shared/models";
import { KeyboardArrowRight } from "@mui/icons-material";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { FormEvent, useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { FileContent } from "use-file-picker/dist/interfaces";
import { WideButton } from "./CreateGiftScreen.styles";
import { UploadMedia } from "@dono-business/shared/components/UploadMedia/UploadMedia";
import { DateTimePickerWithTimezone } from "@dono-business/shared/components/DateTimePickerWithTimeZone";
import { StepContentContainer } from "@dono-business/shared/components";
import { useAppRequests } from "@dono-business/shared/hooks";
import { UnunploadedFileStatus } from "@dono-business/shared/components/UploadMedia";
import { maximumLengthErrorMessage } from "@dono-business/shared/utils/other";
export type Step1Props = {
    onFilesContentChange?: (v?: FileContent[]) => void;
    onSubmit: (event: FormEvent) => void;
    onFileUpload?: (id: string) => void;
    onGiftContentTypeChange?: (type?: "photo" | "video") => void;
    timezone: string;
    onTimezoneChange: (timezone: string) => void;
    formControl: Control<CreateGiftFormFieldsModel>;
    isValid: boolean;
    occasionsList: string[];
    draggableLogo?: string;
};

export const Step1 = ({
    onSubmit,
    onFilesContentChange,
    onFileUpload,
    onGiftContentTypeChange,
    onTimezoneChange,
    timezone,
    formControl: control,
    isValid,
    occasionsList,
    draggableLogo,
}: Step1Props) => {
    const { uploadRequests } = useAppRequests();
    const [isUploading, setIsUploading] = useState(false);
    const [hasUnuploadedFile, setHasUnuploadedFile] = useState<UnunploadedFileStatus>(false);
    const messageTextEditorRef = useRef<TextEditorRef | null>(null);
    const hasUploadError = hasUnuploadedFile === "uploadError";
    return (
        <StepContentContainer component="form">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <Controller
                            control={control}
                            name="title"
                            rules={{ required: { value: true, message: "Title is mandatory" } }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    label="Event Name"
                                    error={Boolean(error)}
                                    helperText={error?.message ?? ""}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </FormControl>
                    {/* TODO: remove companyName field */}
                    <FormControl fullWidth sx={{ background: "white", mt: 2, display: "none" }}>
                        <Controller
                            control={control}
                            name="companyName"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    label="Company"
                                    disabled
                                    error={Boolean(error)}
                                    helperText={error?.message ?? ""}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ background: "white", mt: 2 }}>
                        <InputLabel id="occasion-label">Occasion</InputLabel>
                        <Controller
                            control={control}
                            name="occasion"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    labelId="occasion-label"
                                    label="Occasion"
                                    sx={{ "&.MuiInputBase-root": { borderRadius: 2 } }}
                                    fullWidth
                                >
                                    {occasionsList.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <Controller
                            control={control}
                            name="amount"
                            rules={{ required: { value: true, message: "Amount is mandatory" } }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    label="Default Amount"
                                    type="number"
                                    error={Boolean(error)}
                                    helperText={error?.message ?? ""}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <Controller
                            control={control}
                            name={"scheduleDateTime"}
                            render={({ field }) => (
                                <DateTimePickerWithTimezone
                                    {...field}
                                    controlledTimezone
                                    timezone={timezone}
                                    onTimezoneChange={onTimezoneChange}
                                    slotProps={{
                                        textField: {
                                            label: "Default Delivery Date",
                                            autoComplete: "off",
                                        },
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <Controller
                            rules={{
                                validate: {
                                    characterLimit: () => {
                                        const remaniningLength =
                                            messageTextEditorRef.current?.getRemainingLength() ?? 0;
                                        return remaniningLength >= 0 || maximumLengthErrorMessage;
                                    },
                                },
                            }}
                            control={control}
                            name="message"
                            render={({ field, fieldState: { error } }) => (
                                <TextEditor
                                    uniqueName="message"
                                    {...field}
                                    ref={(el) => {
                                        field.ref(el);
                                        messageTextEditorRef.current = el;
                                    }}
                                    error={error}
                                    characterLimit
                                    label="Default Message"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} container justifyContent={{ xs: "center", md: "flex-end" }}>
                    <UploadMedia
                        onClearUploadedFile={() => {
                            onFileUpload?.("");
                            onFilesContentChange?.();
                            onGiftContentTypeChange?.();
                        }}
                        onUploadStart={() => setIsUploading(true)}
                        onFileSelected={() => setHasUnuploadedFile?.(true)}
                        onClearSelectedFile={() => setHasUnuploadedFile?.(false)}
                        onUploadFinish={(data) => {
                            setIsUploading(false);
                            if (data) {
                                onFilesContentChange?.(data.file.filesContent as FileContent[]);
                                onGiftContentTypeChange?.(data.file.type);
                                onFileUpload?.(data.mediaId);
                            } else setHasUnuploadedFile?.("uploadError");
                        }}
                        uploadPhotoRequest={uploadRequests.uploadPhoto}
                        uploadVideoRequest={uploadRequests.uploadVideo}
                        draggableSrc={draggableLogo}
                    />
                </Grid>
            </Grid>

            <Box mt={2} textAlign="right" display={"flex"} justifyContent="flex-end">
                <WideButton
                    disabled={!isValid || isUploading || hasUploadError}
                    fullWidth={false}
                    type="submit"
                    onClick={(e) => {
                        if (hasUnuploadedFile !== true) onSubmit?.(e);
                    }}
                >
                    {isUploading ? (
                        "Uploading..."
                    ) : (
                        <>
                            Next <KeyboardArrowRight />
                        </>
                    )}
                </WideButton>
            </Box>
        </StepContentContainer>
    );
};
