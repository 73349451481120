import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { CustomDataGrid } from "@dono-business/shared/components";
import { ParsedGiftModel } from "@dono-business/shared/models";
import { useMemo } from "react";
import { IconButton } from "@mui/material";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { amountCellValueFormatter, genericDateAndTimeFormatter } from "@dono-business/shared/utils";
import { Dayjs } from "dayjs";
type ReceipientsGridProps = {
    giftDefaultValues: { amount?: number; message?: string; scheduleDateTime?: Dayjs };
    rowData: ParsedGiftModel[] | null | undefined;
    onRowRemove: (uniqueId: string) => void;
};
export const ReceipientsGrid = ({
    giftDefaultValues: { amount, message, scheduleDateTime },
    rowData,
    onRowRemove,
}: ReceipientsGridProps) => {
    const receipientsGridsColums: GridColDef<ParsedGiftModel>[] = useMemo(
        () => [
            {
                field: "firstName",
                headerName: "First Name",
                minWidth: 150,
                flex: 3,
                align: "left",
                valueFormatter: (value) => value ?? "-",
                valueGetter: (value, row) => row?.giftee?.FirstName,
            },
            {
                field: "lastName",
                headerName: "Last Name",
                minWidth: 150,
                flex: 3,
                align: "left",
                valueFormatter: (value) => value ?? "-",
                valueGetter: (value, row) => row?.giftee?.LastName,
            },
            {
                field: "phoneNumber",
                headerName: "Mobile Number",
                minWidth: 150,
                flex: 3,
                align: "left",
                valueFormatter: (value) => value ?? "-",
                valueGetter: (value, row) => row?.giftee?.phoneNumber,
            },
            {
                field: "email",
                headerName: "Email",
                minWidth: 150,
                flex: 3,
                align: "left",
                valueFormatter: (value) => value ?? "-",
                valueGetter: (value, row) => row?.giftee?.email,
            },
            {
                field: "amount",
                headerName: "Amount",
                minWidth: 150,
                flex: 3,
                align: "left",
                valueFormatter: amountCellValueFormatter,
                valueGetter: (value, row) => row?.amount?.value ?? amount,
            },
            {
                field: "message",
                headerName: "Message",
                minWidth: 150,
                flex: 3,
                align: "left",
                valueFormatter: (value: string) => value?.replace(/<[^>]*>/g, "") ?? "-",
                valueGetter: (value) => value ?? message,
            },

            {
                field: "dateDeliveryScheduled",
                headerName: "Delivery Date",
                minWidth: 150,
                flex: 3,
                type: "date",
                align: "left",
                valueFormatter: (value) => (value ? genericDateAndTimeFormatter(value) : "-"),
                valueGetter: (value) => value ?? scheduleDateTime,
            },
            {
                field: "uniqueId",
                headerName: "",
                align: "left",
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => (
                    <IconButton onClick={() => onRowRemove(params.value)}>
                        <RemoveCircleOutlineOutlinedIcon />
                    </IconButton>
                ),
            },
        ],
        [message, amount, scheduleDateTime, onRowRemove],
    );
    return (
        <CustomDataGrid
            getRowId={(r) => r.uniqueId}
            rows={rowData ?? []}
            columns={receipientsGridsColums}
            autoHeight
            initialState={{ pagination: { paginationModel: { pageSize: 100 } } }}
            pageSizeOptions={[]}
            disableRowSelectionOnClick
        />
    );
};
