import { useAppRequests, useSmallScreenSize } from "@dono-business/shared/hooks";
import { CampaignReportModel } from "@dono-business/shared/models";
import { amountCellValueFormatter } from "@dono-business/shared/utils";
import { Box, Typography } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { CustomDataGrid } from "@dono-business/shared/components/";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExportButton } from "@dono-business/shared/components";

export const ReportScreen = () => {
    const { giftRequests } = useAppRequests();

    const isSmallScreen = useSmallScreenSize();
    const [campaigns, setCampaigns] = useState<CampaignReportModel[]>();

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const navigateToGifts = (campaignId: number | string) =>
        navigate({
            pathname: "/report/gifts",
            search: `?campaignIds=${campaignId}`,
        });
    useEffect(() => {
        setIsLoading(true);
        giftRequests
            .getCampaignsReportList()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((res: any) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const list = res?.data?.items?.map((item: any, index: number) => ({
                    ...item,
                    uniqueId: `unique-${index}`,
                }));
                setCampaigns(list);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [giftRequests]);

    const columns: GridColDef[] = [
        { field: "name", headerName: "Event Name", minWidth: 130, flex: 2, align: "left" },
        {
            field: "fullName",
            headerName: "Sender Name",
            minWidth: 130,
            flex: 2,
            align: "left",
            valueGetter: (value, row) => `${row?.user?.firstName || "-"} ${row?.user?.lastName || ""}`,
        },
        {
            field: "senderEmail",
            headerName: "Sender Email",
            minWidth: 130,
            flex: 2,
            align: "left",
            valueGetter: (value, row) => `${row?.user?.email || "-"}`,
        },
        { field: "occasion", headerName: "Occasion", minWidth: 130, flex: 2, align: "left" },
        {
            field: "giftsCount",
            headerName: "#Gift",
            flex: 1,
            align: "left",
        },
        {
            field: "totalAmount",
            headerName: "Event Total",
            minWidth: 100,
            flex: 1,
            align: "left",
            valueFormatter: amountCellValueFormatter,
        },
        {
            field: "campaignDateCreated",
            headerName: "Create Date",
            minWidth: 100,
            flex: 1,
            align: "left",
            type: "date",
            valueFormatter: (value) => new Date(value).toLocaleDateString(),
        },
    ];
    const apiRef = useGridApiRef();
    return (
        <Box height={640}>
            {isSmallScreen && <Typography variant="screenTitle">Reports</Typography>}
            <Box mb={4} display={"flex"} justifyContent="space-between" alignItems="center">
                <Typography variant="title">Gift Events</Typography>
                {!isLoading && campaigns && (
                    <ExportButton
                        onClick={() =>
                            apiRef.current.exportDataAsCsv({ fileName: `gift_events_${new Date().getTime()}` })
                        }
                    />
                )}
            </Box>

            <CustomDataGrid
                rows={campaigns ?? []}
                loading={isLoading}
                getRowId={(row) => row.id ?? row.uniqueId}
                columns={columns}
                initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                onRowClick={(params) => navigateToGifts(params.id)}
                onCellKeyDown={(params, event) => event.key === "Enter" && navigateToGifts(params.id)}
                apiRef={apiRef}
                showPointerOnHover
            />
        </Box>
    );
};
