import { Grid, TextField, Button, Autocomplete, Box } from "@mui/material";
import { AddReceipientFormFieldsModel, CompanyGifteeModel } from "@dono-business/shared/models";
import React, { SyntheticEvent, useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { validateUSPhoneNumber } from "@dono-business/shared/utils";
import { useCompanyGiftees } from "./useCompanyGiftees";
import { TextEditor, TextEditorRef } from "@dono-business/shared/components/texteditor";
import { DateTimePickerWithTimezone } from "@dono-business/shared/components/DateTimePickerWithTimeZone";

type Props = {
    fieldControl: Control<AddReceipientFormFieldsModel>;
    onSubmit: React.FormEventHandler<HTMLFormElement>;
    datePickerTimezone: string;
};

export const AddReceipientForm = ({ onSubmit, fieldControl: control, datePickerTimezone }: Props) => {
    const {
        options: firstNameFieldOptions,
        fetchOptionsWithDebounce: fetchFirstNameFieldOptionsWithDebounce,
        fetchOptions: fetchFirstNameFieldOptions,
        lastSearchedInput: lastSearchedFirstName,
    } = useCompanyGiftees();
    const {
        options: lastNameFieldOptions,
        fetchOptionsWithDebounce: fetchLastNameFieldOptionsWithDebounce,
        fetchOptions: fetchLastNameFieldOptions,
        lastSearchedInput: lastSearchedLastName,
    } = useCompanyGiftees();
    const {
        options: phoneNumberFieldOptions,
        fetchOptionsWithDebounce: fetchPhoneNumberFieldOptionsWithDebounce,
        fetchOptions: fetchPhoneNumberFieldOptions,
        lastSearchedInput: lastSearchedPhoneNumber,
    } = useCompanyGiftees();
    const {
        options: emailFieldOptions,
        fetchOptionsWithDebounce: fetchEmailFieldOptionsWithDebounce,
        fetchOptions: fetchEmailFieldOptions,
        lastSearchedInput: lastSearchedEmail,
    } = useCompanyGiftees();

    const [firstNameFieldSelectedOption, setFirstNameFieldSelectedOption] = useState<null | CompanyGifteeModel>(null);
    const [lastNameFieldSelectedOption, setLastNameFieldSelectedOption] = useState<null | CompanyGifteeModel>(null);
    const [phoneNumberFieldNameSelectedOption, setPhoneNumberFieldSelectedOption] = useState<null | CompanyGifteeModel>(
        null,
    );
    const [emailFieldNameSelectedOption, setEmailFieldSelectedOption] = useState<null | CompanyGifteeModel>(null);
    const handleAutocompleteFieldChange = (
        value: CompanyGifteeModel | null,
        field: "firstName" | "lastName" | "phoneNumber" | "email",
    ) => {
        if (value) {
            setFirstNameFieldSelectedOption(value);
            setLastNameFieldSelectedOption(value);
            setPhoneNumberFieldSelectedOption(value);
            setEmailFieldSelectedOption(value);
        } else {
            if (field === "firstName") {
                setFirstNameFieldSelectedOption(null);
            } else if (field === "lastName") {
                setLastNameFieldSelectedOption(null);
            } else if (field === "phoneNumber") {
                setPhoneNumberFieldSelectedOption(null);
            } else {
                setEmailFieldSelectedOption(null);
            }
        }
    };
    const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        onSubmit(e);
        setFirstNameFieldSelectedOption(null);
        setLastNameFieldSelectedOption(null);
        setPhoneNumberFieldSelectedOption(null);
        setEmailFieldSelectedOption(null);
    };
    const messageTextEditorRef = useRef<TextEditorRef | null>(null);

    return (
        <Grid item container component="form" onSubmit={handleSubmit}>
            <Grid item container overflow="auto">
                <Grid columns={22} container item spacing={3} flexWrap="nowrap">
                    <Grid xs={3} item minWidth={150}>
                        <Controller
                            name="firstName"
                            control={control}
                            rules={{ required: { value: true, message: "Required" } }}
                            render={({ field, fieldState: { error } }) => {
                                return (
                                    <Autocomplete
                                        inputValue={field.value}
                                        onOpen={() => {
                                            field.value &&
                                                lastSearchedFirstName.current !== field.value &&
                                                fetchFirstNameFieldOptions(field.value);
                                        }}
                                        onInputChange={(_, input, reason) => {
                                            if (reason === "input" || reason === "clear")
                                                fetchFirstNameFieldOptionsWithDebounce(input);
                                            field.onChange(input);
                                        }}
                                        clearOnBlur={false}
                                        options={firstNameFieldOptions}
                                        filterOptions={(x) => x}
                                        getOptionLabel={(option) => option.firstName}
                                        onChange={(_, value) => {
                                            handleAutocompleteFieldChange(value, "firstName");
                                        }}
                                        onBlur={() => {
                                            if (field.value !== firstNameFieldSelectedOption?.firstName)
                                                handleAutocompleteFieldChange(null, "firstName");
                                        }}
                                        value={firstNameFieldSelectedOption}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.gifteeName}>
                                                {option.gifteeName}
                                            </li>
                                        )}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    sx={{ height: 75 }}
                                                    size="small"
                                                    placeholder="First name"
                                                    variant="standard"
                                                    error={Boolean(error)}
                                                    helperText={error?.message ?? ""}
                                                    autoComplete="off"
                                                />
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid xs={3} item minWidth={150}>
                        <Controller
                            name="lastName"
                            control={control}
                            rules={{ required: { value: true, message: "Required" } }}
                            render={({ field, fieldState: { error } }) => {
                                return (
                                    <Autocomplete
                                        inputValue={field.value}
                                        onOpen={() => {
                                            field.value &&
                                                lastSearchedLastName.current !== field.value &&
                                                fetchLastNameFieldOptions(field.value);
                                        }}
                                        onInputChange={(_, input, reason) => {
                                            if (reason === "input" || reason === "clear")
                                                fetchLastNameFieldOptionsWithDebounce(input);

                                            field.onChange(input);
                                        }}
                                        clearOnBlur={false}
                                        options={lastNameFieldOptions}
                                        filterOptions={(x) => x}
                                        getOptionLabel={(option) => option.lastName}
                                        onChange={(_, value) => {
                                            handleAutocompleteFieldChange(value, "lastName");
                                        }}
                                        onBlur={() => {
                                            if (field.value !== lastNameFieldSelectedOption?.lastName)
                                                handleAutocompleteFieldChange(null, "lastName");
                                        }}
                                        value={lastNameFieldSelectedOption}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.gifteeName}>
                                                {option.gifteeName}
                                            </li>
                                        )}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    sx={{ height: 75 }}
                                                    size="small"
                                                    placeholder="Last name"
                                                    variant="standard"
                                                    error={Boolean(error)}
                                                    helperText={error?.message ?? ""}
                                                    autoComplete="off"
                                                />
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid xs={3} item minWidth={150}>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            rules={{
                                required: { value: true, message: "Required" },
                                validate: (value) => validateUSPhoneNumber(value) || "Please enter a valid number",
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Autocomplete
                                    inputValue={field.value}
                                    onOpen={() => {
                                        field.value &&
                                            lastSearchedPhoneNumber.current !== field.value &&
                                            fetchPhoneNumberFieldOptions(field.value);
                                    }}
                                    onInputChange={(_, input, reason) => {
                                        if (reason === "input" || reason === "clear")
                                            fetchPhoneNumberFieldOptionsWithDebounce(input);
                                        field.onChange(input);
                                    }}
                                    clearOnBlur={false}
                                    options={phoneNumberFieldOptions}
                                    filterOptions={(x) => x}
                                    getOptionLabel={(option) => option.phoneNumber}
                                    onChange={(_, value) => {
                                        handleAutocompleteFieldChange(value, "phoneNumber");
                                    }}
                                    onBlur={() => {
                                        if (field.value !== phoneNumberFieldNameSelectedOption?.phoneNumber)
                                            handleAutocompleteFieldChange(null, "phoneNumber");
                                    }}
                                    value={phoneNumberFieldNameSelectedOption}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.gifteeName}>
                                            {option.gifteeName}
                                        </li>
                                    )}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                sx={{ height: 75 }}
                                                size="small"
                                                placeholder="Mobile number"
                                                variant="standard"
                                                prefix="+1"
                                                error={Boolean(error)}
                                                helperText={error?.message ?? ""}
                                                autoComplete="off"
                                            />
                                        );
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={3} item minWidth={150}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Autocomplete
                                    inputValue={field.value === "undefined" ? "" : field.value}
                                    onOpen={() => {
                                        field.value &&
                                            lastSearchedEmail.current !== field.value &&
                                            fetchEmailFieldOptions(field.value);
                                    }}
                                    onInputChange={(_, input, reason) => {
                                        if (reason === "input" || reason === "clear")
                                            fetchEmailFieldOptionsWithDebounce(input);
                                        field.onChange(input);
                                    }}
                                    clearOnBlur={false}
                                    options={emailFieldOptions}
                                    filterOptions={(x) => x}
                                    getOptionLabel={(option) => option.email ?? ""}
                                    onChange={(_, value) => {
                                        handleAutocompleteFieldChange(value, "email");
                                    }}
                                    onBlur={() => {
                                        if (field.value !== emailFieldNameSelectedOption?.email)
                                            handleAutocompleteFieldChange(null, "email");
                                    }}
                                    value={emailFieldNameSelectedOption}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.gifteeName}>
                                            {option.gifteeName}
                                        </li>
                                    )}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                sx={{ height: 75 }}
                                                size="small"
                                                placeholder="Email"
                                                variant="standard"
                                                error={Boolean(error)}
                                                helperText={error?.message ?? ""}
                                                autoComplete="off"
                                            />
                                        );
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={3} item minWidth={150}>
                        <Controller
                            name="amount"
                            control={control}
                            rules={{ required: { value: true, message: "Required" } }}
                            defaultValue={"" as unknown as number}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    sx={{ height: 75 }}
                                    size="small"
                                    placeholder="Gift amount"
                                    variant="standard"
                                    type={"number"}
                                    error={Boolean(error)}
                                    helperText={error?.message ?? ""}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={3} item minWidth={150}>
                        <Controller
                            name="message"
                            control={control}
                            rules={{
                                validate: {
                                    characterLimit: () => {
                                        const remaniningLength =
                                            messageTextEditorRef.current?.getRemainingLength() ?? 0;
                                        return remaniningLength >= 0;
                                    },
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Box
                                    sx={{
                                        "& .texteditor-input": { border: "none" },
                                        "& .ql-toolbar": { display: "none" },
                                        "& .ql-container": {
                                            height: "48px",
                                            fontSize: "1rem",
                                            marginBottom: "27px",
                                            minHeight: "auto !important",
                                            borderRadius: "0 !important",
                                            border: "none",
                                            "& .ql-editor": {
                                                pt: "10px",
                                                px: 0,
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.42) ",
                                                "&:focus": {
                                                    borderBottom: "2px solid rgb(0, 0, 0)",
                                                },
                                                "&.ql-blank::before": {
                                                    left: "0",
                                                },
                                                "&::before": {
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                },
                                            },
                                        },
                                        "& .remaining-length": {
                                            transform: "translateY(calc(-100%))",
                                        },
                                    }}
                                >
                                    <TextEditor
                                        uniqueName="addReceipientForm"
                                        characterLimit
                                        {...field}
                                        ref={(el) => {
                                            field.ref(el);
                                            messageTextEditorRef.current = el;
                                        }}
                                        placeholder="Gift message"
                                    />
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid xs={3} item minWidth={200}>
                        <Controller
                            name="deliveryTime"
                            control={control}
                            render={({ field }) => (
                                <DateTimePickerWithTimezone
                                    {...field}
                                    controlledTimezone
                                    timezone={datePickerTimezone}
                                    onTimezoneChange={() => {}}
                                    disableTimezonePicker
                                    compact
                                    slotProps={{
                                        textField: {
                                            sx: { height: 75 },
                                            size: "small",
                                            variant: "standard",
                                            autoComplete: "off",
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={"auto"} item>
                <Button
                    size="small"
                    variant="outlined"
                    type="submit"
                    sx={{ whiteSpace: "nowrap", marginBottom: { xs: 1, lg: 0 } }}
                >
                    Add Gift +
                </Button>
            </Grid>
        </Grid>
    );
};
