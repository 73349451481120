import { useSmallScreenSize } from "@dono-business/shared/hooks";
import {
    StepFourColoredIcon,
    StepFourGrayIcon,
    StepOneIcon,
    StepThreeColoredIcon,
    StepThreeGrayIcon,
    StepTwoColoredIcon,
    StepTwoGrayIcon,
} from "@dono-business/shared/icons";
import { GiftModel, PaymentMethodModel } from "@dono-business/shared/models";
import { useBucksBalance } from "@dono-business/shared/screens";
import { getToken } from "@dono-business/shared/utils";
import { Step, StepContentProps, Stepper, Typography } from "@mui/material";
import { useOccasionsList } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileContent } from "use-file-picker/dist/interfaces";
import logo from "@dono-business/shared/images/logo.svg";
import { Label, StyledStepConnector, StyledStepContent, StyledStepLabel } from "./CreateGiftScreen.styles";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { useCreateGiftForm } from "./useCreateGiftForm";
import { useCompany } from "@dono-business/shared/hooks/useCompany";
import { useAppTimezone } from "@dono-business/shared/components";

export const CreateGiftScreen = () => {
    const isSmallScreen = useSmallScreenSize();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { isValid },
        watch,
        setValue,
    } = useCreateGiftForm();
    const { scheduleDateTime, title, message, occasion, amount, companyName } = watch();
    const { appTimezone, setAppTimezone } = useAppTimezone();
    const [activeStep, setActiveStep] = useState(0);
    const [mediaId, setMediaId] = useState("");
    const [giftContentType, setGiftContentType] = useState<"photo" | "video">();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodModel>();
    const [filesContent, setFilesContent] = useState<FileContent[]>();

    const [gifts, setGifts] = useState<GiftModel[]>([]);
    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate("/login");
        }
    }, [navigate]);
    const company = useCompany();
    useEffect(() => {
        setValue("companyName", company?.name ?? "");
    }, [setValue, company]);
    const handleStep1Submit = () => {
        setActiveStep((step) => step + 1);
    };
    const occasionsList = useOccasionsList();
    const amountToPay = useMemo(() => gifts?.reduce((amount, gift) => gift.amount.value + amount, 0), [gifts]);
    const [shouldUseBucksBalance, setShouldUseBucksBalance] = useState(false);
    const { bucksBalance } = useBucksBalance();
    useEffect(() => {
        setShouldUseBucksBalance(!!bucksBalance?.availableBalance?.value);
    }, [bucksBalance]);
    const scrollToTopOfStep = (element: HTMLElement) =>
        element.closest(".MuiStep-root")?.scrollIntoView({ behavior: "smooth" });
    const stepContentProps: StepContentProps = {
        transitionDuration: 0,
        TransitionProps: {
            unmountOnExit: false,
            onEntered: scrollToTopOfStep,
        },
    };
    const draggableLogo = company?.logo ?? logo;
    return (
        <>
            {isSmallScreen && <Typography variant="screenTitle">Create & Send Gift</Typography>}

            <Stepper activeStep={activeStep} orientation="vertical" connector={<StyledStepConnector />}>
                <Step>
                    <StyledStepLabel icon={<StepOneIcon />}>
                        <Label>Gift Details</Label>
                    </StyledStepLabel>

                    <StyledStepContent {...stepContentProps}>
                        <Step1
                            onSubmit={handleSubmit(handleStep1Submit)}
                            onFilesContentChange={setFilesContent}
                            onGiftContentTypeChange={setGiftContentType}
                            onFileUpload={setMediaId}
                            timezone={appTimezone}
                            onTimezoneChange={setAppTimezone}
                            formControl={control}
                            isValid={isValid}
                            occasionsList={occasionsList ?? ["Holidays"]}
                            draggableLogo={draggableLogo}
                        />
                    </StyledStepContent>
                </Step>
                <Step>
                    <StyledStepLabel icon={activeStep < 1 ? <StepTwoGrayIcon /> : <StepTwoColoredIcon />}>
                        <Label>Recipients</Label>
                    </StyledStepLabel>
                    <StyledStepContent {...stepContentProps}>
                        <Step2
                            occasion={occasion}
                            scheduleDateTime={scheduleDateTime}
                            message={message}
                            amount={amount}
                            mediaId={mediaId}
                            giftContentType={giftContentType}
                            companyName={companyName}
                            title={title}
                            timezone={appTimezone}
                            onNext={() => setActiveStep((step) => step + 1)}
                            onPrev={() => setActiveStep((step) => step - 1)}
                            onGiftsCreate={setGifts}
                        />
                    </StyledStepContent>
                </Step>

                <Step>
                    <StyledStepLabel icon={activeStep < 2 ? <StepThreeGrayIcon /> : <StepThreeColoredIcon />}>
                        <Label>Payment Method</Label>
                    </StyledStepLabel>
                    <StyledStepContent {...stepContentProps}>
                        <Step3
                            rawAmount={amountToPay}
                            onSelectedPaymentMethodChange={setSelectedPaymentMethod}
                            setShouldUseBucksBalance={setShouldUseBucksBalance}
                            shouldUseBucksBalance={shouldUseBucksBalance}
                            bucksBalance={bucksBalance}
                            onNext={() => setActiveStep((step) => step + 1)}
                            onPrev={() => setActiveStep((step) => step - 1)}
                        />
                    </StyledStepContent>
                </Step>

                <Step sx={{ mb: 4 }}>
                    <StyledStepLabel icon={activeStep < 3 ? <StepFourGrayIcon /> : <StepFourColoredIcon />}>
                        <Label>Review & Confirm</Label>
                    </StyledStepLabel>
                    <StyledStepContent {...stepContentProps}>
                        <Step4
                            title={title}
                            occasion={occasion}
                            message={message}
                            amount={selectedPaymentMethod?.includeFeeAmount.value}
                            filesContent={filesContent}
                            giftContentType={giftContentType}
                            selectedPaymentMethod={selectedPaymentMethod}
                            gifts={gifts}
                            shouldUseBucksBalance={shouldUseBucksBalance}
                            onPrev={() => setActiveStep((step) => step - 1)}
                        />
                    </StyledStepContent>
                </Step>
            </Stepper>
        </>
    );
};
