import React, { SVGProps } from "react";
export const StepFourGrayIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="119" height="120" viewBox="0 0 119 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <g filter="url(#filter0_d_11_1237)">
                    <path
                        d="M91 14.0039H28C21.3726 14.0039 16 19.3765 16 26.0039V89.0039C16 95.6313 21.3726 101.004 28 101.004H91C97.6274 101.004 103 95.6313 103 89.0039V26.0039C103 19.3765 97.6274 14.0039 91 14.0039Z"
                        fill="url(#paint0_linear_11_1237)"
                    />
                    <path
                        d="M91 14.5039H28C21.6487 14.5039 16.5 19.6526 16.5 26.0039V89.0039C16.5 95.3552 21.6487 100.504 28 100.504H91C97.3513 100.504 102.5 95.3552 102.5 89.0039V26.0039C102.5 19.6526 97.3513 14.5039 91 14.5039Z"
                        stroke="white"
                        strokeOpacity="0.01"
                    />
                </g>
            </g>
            <path
                d="M59.668 65.544H66.604V71.188H59.668V82H53.276V71.188H31.108V65.204L51.576 33.856H59.668V65.544ZM53.276 41.404H53.14L37.908 65.544H53.276V41.404Z"
                fill="#0C2145"
            />
            <path
                d="M67.6161 88.8688L53.7241 74.9778C52.8891 74.1428 51.3751 71.1568 53.7241 68.9328C54.5467 68.1719 55.626 67.7492 56.7466 67.7492C57.8671 67.7492 58.9465 68.1719 59.7691 68.9328L69.1271 78.2908L89.1711 58.2468C90.0061 57.4118 93.1331 56.2618 95.2161 58.2468C97.2991 60.2318 96.0511 63.4568 95.2161 64.2918L70.6381 88.8688C70.2373 89.2695 69.6938 89.4946 69.1271 89.4946C68.5604 89.4946 68.0168 89.2695 67.6161 88.8688Z"
                fill="#D1D5DE"
                stroke="white"
            />
            <path
                d="M81.5985 75.175L83.5982 73.1753C83.7358 73.0376 83.8132 72.8509 83.8132 72.6562C83.8132 72.4616 83.7358 72.2749 83.5982 72.1372C83.4605 71.9996 83.2738 71.9223 83.0792 71.9223C82.8845 71.9223 82.6978 71.9996 82.5601 72.1372L80.5604 74.1369C80.4228 74.2746 80.3455 74.4613 80.3455 74.6559C80.3455 74.8506 80.4228 75.0373 80.5604 75.175C80.6981 75.3126 80.8848 75.3899 81.0795 75.3899C81.2741 75.3899 81.4608 75.3126 81.5985 75.175V75.175Z"
                fill="white"
            />
            <path
                d="M72.0754 84.6981L78.981 77.7925C79.1186 77.6548 79.196 77.4681 79.196 77.2734C79.196 77.0788 79.1186 76.8921 78.981 76.7544C78.8433 76.6168 78.6566 76.5394 78.462 76.5394C78.2673 76.5394 78.0806 76.6168 77.943 76.7544L71.0373 83.66C70.8997 83.7977 70.8224 83.9844 70.8224 84.179C70.8224 84.3737 70.8997 84.5604 71.0373 84.6981C71.175 84.8357 71.3617 84.913 71.5564 84.913C71.751 84.913 71.9377 84.8357 72.0754 84.6981V84.6981Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_11_1237"
                    x="0"
                    y="0.00390625"
                    width="119"
                    height="119"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.239216 0 0 0 0 0.121569 0 0 0 0 0.262745 0 0 0 0.2 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11_1237" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11_1237" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_11_1237"
                    x1="94.3"
                    y1="-13.8361"
                    x2="24.613"
                    y2="103.266"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C6DA" />
                    <stop offset="1" stopColor="#0C2145" />
                </linearGradient>
            </defs>
        </svg>
    );
};
