import { CreateGiftFormFieldsModel } from "@dono-business/shared/models";
import { useMemo } from "react";
import { useForm, UseFormProps } from "react-hook-form";
export const useCreateGiftForm = (formOptions?: UseFormProps<CreateGiftFormFieldsModel>) => {
    const createGiftFormDefaultValues: CreateGiftFormFieldsModel = useMemo(
        () => ({
            title: "",
            companyName: "",
            occasion: "Holidays",
            amount: 50,
            message: "",
        }),
        [],
    );
    return useForm<CreateGiftFormFieldsModel>({
        defaultValues: createGiftFormDefaultValues,
        mode: "onChange",
        ...formOptions,
    });
};
