import React, { SVGProps } from "react";
export const StepThreeColoredIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="119" height="118" viewBox="0 0 119 118" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_31_423)">
                <path
                    d="M91 14H28C21.3726 14 16 19.3726 16 26V88C16 94.6274 21.3726 100 28 100H91C97.6274 100 103 94.6274 103 88V26C103 19.3726 97.6274 14 91 14Z"
                    fill="url(#paint0_linear_31_423)"
                />
                <path
                    d="M91 14.5H28C21.6487 14.5 16.5 19.6487 16.5 26V88C16.5 94.3513 21.6487 99.5 28 99.5H91C97.3513 99.5 102.5 94.3513 102.5 88V26C102.5 19.6487 97.3513 14.5 91 14.5Z"
                    stroke="white"
                    strokeOpacity="0.01"
                />
            </g>
            <path
                d="M43.484 54.12H45.592C46.952 54.12 48.2667 54.0067 49.536 53.78C50.8507 53.508 52.0067 53.0773 53.004 52.488C54.0013 51.8533 54.7947 51.0147 55.384 49.972C56.0187 48.9293 56.336 47.6373 56.336 46.096C56.336 44.872 56.1093 43.784 55.656 42.832C55.2027 41.8347 54.568 40.996 53.752 40.316C52.9813 39.636 52.0747 39.1147 51.032 38.752C50.0347 38.344 48.9693 38.14 47.836 38.14C45.796 38.14 44.0507 38.6613 42.6 39.704C41.1947 40.7467 40.1293 42.1973 39.404 44.056L33.42 41.948C34.5987 39.0467 36.48 36.78 39.064 35.148C41.6933 33.4707 44.708 32.632 48.108 32.632C50.1027 32.632 51.984 32.9267 53.752 33.516C55.5653 34.06 57.152 34.876 58.512 35.964C59.872 37.052 60.9373 38.412 61.708 40.044C62.524 41.676 62.932 43.5573 62.932 45.688C62.932 47.048 62.7053 48.3173 62.252 49.496C61.844 50.6747 61.2547 51.74 60.484 52.692C59.7587 53.644 58.8747 54.46 57.832 55.14C56.7893 55.82 55.656 56.3187 54.432 56.636V56.772C55.8373 57.044 57.1293 57.52 58.308 58.2C59.532 58.88 60.5747 59.7413 61.436 60.784C62.2973 61.7813 62.9773 62.9373 63.476 64.252C63.9747 65.5667 64.224 66.9947 64.224 68.536C64.224 70.9387 63.7707 73.0693 62.864 74.928C61.9573 76.7413 60.756 78.26 59.26 79.484C57.764 80.708 56.0413 81.6373 54.092 82.272C52.1427 82.9067 50.148 83.224 48.108 83.224C44.3 83.224 40.9453 82.3627 38.044 80.64C35.1427 78.9173 33.0347 76.2427 31.72 72.616L37.84 70.576C38.5653 72.6613 39.7893 74.3613 41.512 75.676C43.28 76.9907 45.4333 77.648 47.972 77.648C49.196 77.648 50.3747 77.4667 51.508 77.104C52.6867 76.696 53.7293 76.1067 54.636 75.336C55.5427 74.5653 56.268 73.6133 56.812 72.48C57.356 71.3467 57.628 70.0093 57.628 68.468C57.628 66.7907 57.2653 65.3853 56.54 64.252C55.8147 63.1187 54.8627 62.212 53.684 61.532C52.5507 60.8067 51.2587 60.308 49.808 60.036C48.3573 59.7187 46.9293 59.56 45.524 59.56H43.484V54.12Z"
                fill="#0C2145"
            />
            <path
                d="M63.4542 83.1112H92.8682C93.8534 83.1101 94.798 82.7228 95.4946 82.0341C96.1913 81.3455 96.5831 80.4118 96.5842 79.4379V61.6616C96.5831 60.6877 96.1913 59.754 95.4946 59.0653C94.798 58.3767 93.8534 57.9893 92.8682 57.9883H63.4552C62.4705 57.9901 61.5267 58.3778 60.8307 59.0664C60.1347 59.7549 59.7432 60.6882 59.7422 61.6616V79.4379C59.7432 80.4118 60.1351 81.3455 60.8317 82.0341C61.5284 82.7228 62.473 83.1101 63.4582 83.1112H63.4542Z"
                fill="#B39CB8"
            />
            <path
                d="M66.8637 63.5383H67.1487C67.3032 63.5383 67.4514 63.4777 67.5606 63.3697C67.6699 63.2617 67.7312 63.1152 67.7312 62.9625C67.7312 62.8098 67.6699 62.6634 67.5606 62.5554C67.4514 62.4474 67.3032 62.3867 67.1487 62.3867H66.8637C66.7093 62.3867 66.5611 62.4474 66.4518 62.5554C66.3426 62.6634 66.2813 62.8098 66.2812 62.9625C66.2813 63.1152 66.3426 63.2617 66.4518 63.3697C66.5611 63.4777 66.7093 63.5383 66.8637 63.5383Z"
                fill="#B39CB8"
            />
            <path
                d="M67.4818 74.0391H65.2388C65.0843 74.0391 64.9361 74.0997 64.8269 74.2077C64.7176 74.3157 64.6562 74.4621 64.6562 74.6149C64.6562 74.7676 64.7176 74.914 64.8269 75.022C64.9361 75.13 65.0843 75.1907 65.2388 75.1907H67.4818C67.6362 75.1907 67.7844 75.13 67.8936 75.022C68.0029 74.914 68.0643 74.7676 68.0643 74.6149C68.0643 74.4621 68.0029 74.3157 67.8936 74.2077C67.7844 74.0997 67.6362 74.0391 67.4818 74.0391Z"
                fill="#B39CB8"
            />
            <path
                d="M78.1635 74.0391H70.4185C70.264 74.0391 70.1158 74.0997 70.0066 74.2077C69.8973 74.3157 69.8359 74.4621 69.8359 74.6149C69.8359 74.7676 69.8973 74.914 70.0066 75.022C70.1158 75.13 70.264 75.1907 70.4185 75.1907H78.1635C78.3179 75.1907 78.4661 75.13 78.5753 75.022C78.6846 74.914 78.7459 74.7676 78.7459 74.6149C78.7459 74.4621 78.6846 74.3157 78.5753 74.2077C78.4661 74.0997 78.3179 74.0391 78.1635 74.0391Z"
                fill="#B39CB8"
            />
            <path
                d="M63.4542 83.1112H92.8682C93.8534 83.1101 94.798 82.7228 95.4946 82.0341C96.1913 81.3455 96.5831 80.4118 96.5842 79.4379V61.6616C96.5831 60.6877 96.1913 59.754 95.4946 59.0653C94.798 58.3767 93.8534 57.9893 92.8682 57.9883H63.4552C62.4705 57.9901 61.5267 58.3778 60.8307 59.0664C60.1347 59.7549 59.7432 60.6882 59.7422 61.6616V79.4379C59.7432 80.4118 60.1351 81.3455 60.8317 82.0341C61.5284 82.7228 62.473 83.1101 63.4582 83.1112H63.4542ZM63.4542 59.1389H92.8682C93.5445 59.1397 94.1929 59.4056 94.6711 59.8784C95.1494 60.3511 95.4184 60.992 95.4192 61.6606V62.3871H69.1772C69.0227 62.3871 68.8745 62.4478 68.7653 62.5558C68.656 62.6638 68.5947 62.8102 68.5947 62.9629C68.5947 63.1156 68.656 63.2621 68.7653 63.3701C68.8745 63.4781 69.0227 63.5387 69.1772 63.5387H95.4192V66.3985H87.7552C87.6007 66.3985 87.4525 66.4592 87.3433 66.5671C87.2341 66.6751 87.1727 66.8216 87.1727 66.9743C87.1727 67.127 87.2341 67.2735 87.3433 67.3814C87.4525 67.4894 87.6007 67.5501 87.7552 67.5501H95.4192V79.4379C95.4184 80.1064 95.1494 80.7474 94.6711 81.2201C94.1929 81.6928 93.5445 81.9588 92.8682 81.9595H63.4542C62.7779 81.9588 62.1295 81.6928 61.6512 81.2201C61.173 80.7474 60.904 80.1064 60.9032 79.4379V67.5491H85.3152C85.4697 67.5491 85.6178 67.4884 85.7271 67.3805C85.8363 67.2725 85.8977 67.126 85.8977 66.9733C85.8977 66.8206 85.8363 66.6741 85.7271 66.5661C85.6178 66.4582 85.4697 66.3975 85.3152 66.3975H60.9032V63.5397H64.6822C64.8367 63.5397 64.9848 63.4791 65.0941 63.3711C65.2033 63.2631 65.2647 63.1166 65.2647 62.9639C65.2647 62.8112 65.2033 62.6648 65.0941 62.5568C64.9848 62.4488 64.8367 62.3881 64.6822 62.3881H60.9032V61.6616C60.9037 60.9928 61.1726 60.3517 61.6509 59.8787C62.1291 59.4058 62.7777 59.1397 63.4542 59.1389Z"
                fill="white"
            />
            <path
                d="M66.8637 63.5383H67.1487C67.3032 63.5383 67.4514 63.4777 67.5606 63.3697C67.6699 63.2617 67.7312 63.1152 67.7312 62.9625C67.7312 62.8098 67.6699 62.6634 67.5606 62.5554C67.4514 62.4474 67.3032 62.3867 67.1487 62.3867H66.8637C66.7093 62.3867 66.5611 62.4474 66.4518 62.5554C66.3426 62.6634 66.2813 62.8098 66.2812 62.9625C66.2813 63.1152 66.3426 63.2617 66.4518 63.3697C66.5611 63.4777 66.7093 63.5383 66.8637 63.5383Z"
                fill="white"
            />
            <path
                d="M67.4818 74.0391H65.2388C65.0843 74.0391 64.9361 74.0997 64.8269 74.2077C64.7176 74.3157 64.6562 74.4621 64.6562 74.6149C64.6562 74.7676 64.7176 74.914 64.8269 75.022C64.9361 75.13 65.0843 75.1907 65.2388 75.1907H67.4818C67.6362 75.1907 67.7844 75.13 67.8936 75.022C68.0029 74.914 68.0643 74.7676 68.0643 74.6149C68.0643 74.4621 68.0029 74.3157 67.8936 74.2077C67.7844 74.0997 67.6362 74.0391 67.4818 74.0391Z"
                fill="white"
            />
            <path
                d="M78.1635 74.0391H70.4185C70.264 74.0391 70.1158 74.0997 70.0066 74.2077C69.8973 74.3157 69.8359 74.4621 69.8359 74.6149C69.8359 74.7676 69.8973 74.914 70.0066 75.022C70.1158 75.13 70.264 75.1907 70.4185 75.1907H78.1635C78.3179 75.1907 78.4661 75.13 78.5753 75.022C78.6846 74.914 78.7459 74.7676 78.7459 74.6149C78.7459 74.4621 78.6846 74.3157 78.5753 74.2077C78.4661 74.0997 78.3179 74.0391 78.1635 74.0391Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_31_423"
                    x="0"
                    y="0"
                    width="119"
                    height="118"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.239216 0 0 0 0 0.121569 0 0 0 0 0.262745 0 0 0 0.2 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_423" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_423" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_31_423"
                    x1="69.331"
                    y1="19.59"
                    x2="10.814"
                    y2="133.577"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B993C2" />
                    <stop offset="1" stopColor="#5D4A61" />
                </linearGradient>
            </defs>
        </svg>
    );
};
