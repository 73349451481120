/* eslint-disable no-async-promise-executor */
import { getToken, login, logout } from '@dono-business/shared/utils';
import axios, { AxiosRequestConfig } from 'axios';
import { firebase } from 'config';
import { initializeApp } from 'firebase/app';
import { getAuth, User } from 'firebase/auth';
import toast from 'react-simple-toasts';

import { authRequests } from '.';

const customAxios = axios.create();

// TODO: move firebase to a new util 
const app = initializeApp(firebase);
export const firebaseAuth = getAuth(app);

const refreshToken = (originalRequest: AxiosRequestConfig) => {
    return new Promise<AxiosRequestConfig>(async (resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (originalRequest as any)._retry = true;
        const firebaseToken = await firebaseAuth.currentUser?.getIdToken(true);
        if (firebaseAuth.currentUser == null) reject();

        const { phoneNumber, email } = firebaseAuth.currentUser as User;

        if (firebaseToken) {
            const response =
                (phoneNumber && (await authRequests.login(`${phoneNumber}`, firebaseToken))) ||
                (email && (await authRequests.emailLogin(email, firebaseToken)));

            if (response) {
                const { id, phone, xtoken } = response.data;
                login(id, phone, xtoken, email || undefined);
                originalRequest.headers = {
                    ...originalRequest.headers,
                    "x-token": xtoken,
                };
                // originalRequest.headers["x-token"] = xtoken;
                resolve(originalRequest);
            } else {
                reject();
            }
        } else {
            reject();
        }
    });
};

// Add a request interceptor
customAxios.interceptors.request.use(
    (config) => {
        const xToken = getToken();
        if (xToken) {
            config.headers = {
                ...config.headers,
                "x-token": xToken,
            };
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Add a response interceptor
customAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            return refreshToken(originalRequest).then(customAxios).catch(logout);
        } else {
            toast(error.message);
        }

        return Promise.reject(error);
    },
);

export default customAxios;
