import { GridColDef } from "@mui/x-data-grid";
import { ReportGiftModel, ReportGiftsRequestParamsModel } from "@dono-business/shared/models";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    amountCellValueFormatter,
    createDropDownFilterOptionsFromEnum,
    formatToInternational,
} from "@dono-business/shared/utils";
import { useSearchParams } from "react-router-dom";
import { useAppRequests, useSmallScreenSize } from "@dono-business/shared/hooks";
import { capitalize } from "@mui/material";
import { GiftStatus } from "@dono-business/shared/models/gift-status";
export const useReportGiftScreenHelper = () => {
    const { giftRequests } = useAppRequests();

    const [allReportGifts, setAllReportGifts] = useState<ReportGiftModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState<ReportGiftsRequestParamsModel>({});
    const [searchParams] = useSearchParams();
    const isSmallScreen = useSmallScreenSize();

    useEffect(() => {
        if (params.campaignIds) {
            setIsLoading(true);
            giftRequests
                .getAllCampaignGifts(params.campaignIds)
                .then((res) => {
                    setAllReportGifts(res.data.items);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [params.campaignIds, giftRequests]);
    useEffect(() => {
        const { origin, pathname } = window.location;
        const url = `${origin}${pathname}`;
        if (params.campaignIds?.length) {
            window.history.replaceState({}, "", `${url}?campaignIds=${params.campaignIds.join(",")}`);
        } else window.history.replaceState({}, "", url);
    }, [params.campaignIds]);

    useEffect(() => {
        const campaignIds = searchParams
            .get("campaignIds")
            ?.split(",")
            ?.map((i) => +i);
        if (campaignIds) {
            setParams({ campaignIds });
        }
    }, [searchParams]);

    const getFilteredReportGifts = useCallback((params: ReportGiftsRequestParamsModel, data: ReportGiftModel[]) => {
        let result = data;
        if (params?.status && params.status.length) {
            result = result?.filter(
                (gift) =>
                    params.status &&
                    params.status.some((item) => item.toLowerCase() === gift.giftStatus?.toLowerCase()),
            );
        }
        if (params?.userId && params.userId.length) {
            result = result?.filter((gift) => params.userId && params.userId.some((item) => +item === gift.gifter.id));
        }
        if (params?.occasion && params.occasion.length) {
            result = result?.filter((gift) => params.occasion?.some((item) => item === gift.occasion));
        }
        if (params?.amount && params.amount.length) {
            result = result?.filter((gift) =>
                params?.amount?.some(({ amountFrom, amountTo }) => {
                    if (amountFrom !== undefined && amountTo !== undefined) {
                        return amountFrom <= gift.amount.value && gift.amount.value <= amountTo;
                    } else if (amountFrom !== undefined) return amountFrom <= gift.amount.value;
                    else if (amountTo !== undefined) return gift.amount.value <= amountTo;
                    return true;
                }),
            );
        }

        if (params?.createDateFrom) {
            result = result?.filter(
                (gift) => params?.createDateFrom && new Date(gift?.giftDateCreated) >= new Date(params?.createDateFrom),
            );
        }
        if (params?.createDateTo) {
            result = result?.filter(
                (gift) => params?.createDateTo && new Date(gift?.giftDateCreated) <= new Date(params?.createDateTo),
            );
        }
        return result;
    }, []);

    const reportGifts = getFilteredReportGifts(params, allReportGifts);

    const title = useMemo(() => {
        const eventNames = reportGifts?.map((ev) => ev?.campaign?.name);
        const eventNamesSet = [...(new Set(eventNames) as never)];
        const eventNamesString = eventNamesSet?.toString().replaceAll(",", " and ");
        if (eventNamesSet?.length > 3) {
            return "Multiple Events - Gifts";
        }
        if (eventNamesString) {
            return `${eventNamesString} - Gifts`;
        }
        return "Gifts";
    }, [reportGifts]);

    const giftsGridColumns: GridColDef[] = useMemo(
        () => [
            {
                field: "campaignName",
                headerName: "Event Name",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter: (value, row) => row?.campaign?.name,
            },
            {
                field: "giftDateCreated",
                headerName: "Create Date",
                minWidth: 150,
                flex: 1,
                align: "left",
                type: "date",
                valueFormatter: (value) => new Date(value).toLocaleDateString(),
            },
            {
                field: "sender",
                headerName: "Sender",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter: (value, row) => row?.gifter.email || row?.gifter.phoneNumber || row?.gifter.id,
            },
            { field: "occasion", headerName: "Occasion", flex: 100, align: "left" },
            {
                field: "recipient",
                headerName: "Phone Number",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter: (value, row) => formatToInternational(row?.gifteePhoneNumber),
            },
            {
                field: "recipientName",
                headerName: "Recipient Name",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter: (value, row) => row?.gifteeName,
            },
            {
                field: "recipientEmail",
                headerName: "Recipient Email",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter: (value, row) => row?.gifteeEmail ?? "-",
            },
            {
                field: "amount",
                headerName: "Amount",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueFormatter: amountCellValueFormatter,
                valueGetter: (value, row) => row?.amount?.value ?? "-",
            },
            {
                field: "status",
                headerName: "Status",
                minWidth: 150,
                flex: 1,
                align: "left",
                valueGetter: (value, row) => row?.giftStatus,
                valueFormatter(value) {
                    return value ? capitalize(value) : "-";
                },
                type: "singleSelect",
                valueOptions: createDropDownFilterOptionsFromEnum(GiftStatus),
            },
        ],
        [],
    );

    return { title, giftsGridColumns, reportGifts, isLoading, params, setParams, isSmallScreen };
};
