import { Button, FormControl, Grid, InputLabel, IconButton, MenuItem, Select } from "@mui/material";
import {
    AmountItem,
    CampaignModel,
    CompanyUserModel,
    ReportGiftsRequestParamsModel,
} from "@dono-business/shared/models";
import React, { useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { Container, StyledFilterIcon } from "./ReportGiftsFilter.styles";
import { useOccasionsList } from "hooks";
import { DateRange, DateRangePicker } from "@dono-business/shared/components/date-range-picker";
import { useAppRequests } from "@dono-business/shared/hooks";

const statusList = ["paid", "accepted", "canceled", "delivered"];
const amountList: AmountItem[] = [
    {
        label: "$1 - 50",
        amountFrom: 1,
        amountTo: 50,
    },
    {
        label: "$51 - 100",
        amountFrom: 51,
        amountTo: 100,
    },
    {
        label: "$101 - 200",
        amountFrom: 101,
        amountTo: 200,
    },
    {
        label: "$201 - 500",
        amountFrom: 201,
        amountTo: 500,
    },
];
type ReportGiftsFilterProps = {
    preSelectedCampaignIds?: number[];
    onFilterSubmit?: (params: ReportGiftsRequestParamsModel) => void;
};
export const ReportGiftsFilter = ({ preSelectedCampaignIds, onFilterSubmit }: ReportGiftsFilterProps) => {
    const { giftRequests } = useAppRequests();

    const [occasion, setOccasion] = useState<string[]>([]);
    const [status, setStatus] = useState<string[]>([]);
    const [campaignIds, setCampaignIds] = useState<number[]>([]);
    const [userId, setUserId] = useState<string[]>([]);
    const [dateRange, setDateRange] = useState<DateRange>();

    const [amountLabel, setAmountLabel] = useState<string[]>([]);
    const [usersList, setUsersList] = useState<CompanyUserModel[]>();
    const [campaignList, setCampaignList] = useState<CampaignModel[]>();

    const occasionsList = useOccasionsList() ?? [];
    const amountItems = useMemo(() => {
        const selected: AmountItem[] = [];
        amountLabel.forEach((item) => {
            const originalAmountItem = amountList.find((amount) => amount.label === item);
            if (originalAmountItem) selected.push(originalAmountItem);
        });

        return selected;
    }, [amountLabel]);

    const resetForm = () => {
        // setCampaignIds([]);
        setOccasion([]);
        setStatus([]);
        setAmountLabel([]);
        setUserId([]);
        setDateRange(undefined);
    };

    useEffect(() => {
        giftRequests.getCampaignsReportList().then((res) => setCampaignList(res?.data?.items));
        giftRequests.getCompanyUsers().then((res) => setUsersList(res?.data));
    }, [giftRequests]);

    useEffect(() => {
        if (preSelectedCampaignIds) {
            setCampaignIds(preSelectedCampaignIds);
        }
    }, [preSelectedCampaignIds]);

    useEffect(() => {
        onFilterSubmit?.({
            status,
            occasion,
            campaignIds: campaignIds,
            createDateFrom: dateRange?.startDate,
            createDateTo: dateRange?.endDate,
            amount: amountItems,
            userId,
        });
    }, [status, occasion, campaignIds, dateRange, amountItems, userId, onFilterSubmit]);

    return (
        <Container container spacing={3} m={0} p={2} pt={3} maxWidth="100%" position={"relative"}>
            <StyledFilterIcon />
            <Grid item md={4} xs={12} p={1}>
                <FormControl fullWidth>
                    <InputLabel id="occasion-label">Occasion</InputLabel>
                    <Select
                        labelId="occasion-label"
                        label="Occasion"
                        value={occasion}
                        onChange={({ target: { value } }) => {
                            if (Array.isArray(value)) setOccasion(value);
                        }}
                        fullWidth
                        multiple
                    >
                        {occasionsList.map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                    <ResetIcon onClick={() => setOccasion([])} />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12} p={1}>
                <FormControl fullWidth>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                        labelId="status-label"
                        label="Status"
                        value={status}
                        onChange={({ target: { value } }) => {
                            if (Array.isArray(value)) setStatus(value);
                        }}
                        fullWidth
                        multiple
                    >
                        {statusList.map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                    <ResetIcon onClick={() => setStatus([])} />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12} p={1}>
                <FormControl fullWidth>
                    <InputLabel id="amount-label">Amount</InputLabel>
                    <Select
                        fullWidth
                        labelId="amount-label"
                        label="Amount"
                        value={amountLabel}
                        onChange={({ target: { value } }) => {
                            if (Array.isArray(value)) setAmountLabel(value);
                        }}
                        multiple
                    >
                        {amountList.map((item, index) => (
                            <MenuItem key={item.label} value={item.label}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <ResetIcon onClick={() => setAmountLabel([])} />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12} p={1}>
                <FormControl fullWidth>
                    <InputLabel id="campaign-label">Campaign</InputLabel>
                    <Select
                        labelId="campaign-label"
                        label="Campaign"
                        value={campaignIds}
                        onChange={(event) => {
                            const {
                                target: { value },
                            } = event;
                            setCampaignIds(typeof value === "string" ? value.split(",").map((i) => +i) : value);
                        }}
                        multiple
                        fullWidth
                    >
                        {campaignList?.map((item) => (
                            <MenuItem key={item.name} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <ResetIcon onClick={() => setCampaignIds([])} />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12} p={1}>
                <FormControl fullWidth>
                    <InputLabel id="user-label">User</InputLabel>
                    <Select
                        labelId="user-label"
                        label="User"
                        value={userId}
                        onChange={({ target: { value } }) => {
                            if (Array.isArray(value)) setUserId(value);
                        }}
                        fullWidth
                        multiple
                    >
                        {usersList?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.firstName || item.lastName ? (
                                    <>
                                        {item.firstName} {item.lastName}
                                    </>
                                ) : (
                                    item?.email
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                    <ResetIcon onClick={() => setUserId([])} />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12} p={1}>
                <FormControl fullWidth>
                    <DateRangePicker
                        label="Date range"
                        variant="outlined"
                        range={dateRange}
                        onRangeChange={setDateRange}
                    />
                    <ResetIcon onClick={() => setDateRange(undefined)} />
                </FormControl>
            </Grid>
            <Button
                variant="outlined"
                fullWidth={false}
                onClick={resetForm}
                sx={{
                    position: "absolute",
                    right: "36px",
                    top: 0,
                    transform: "translateY(-50%)",
                    border: "none",
                    fontSize: 14,
                    color: "#434E82",
                    "&:hover": { border: "none" },
                }}
            >
                Remove filters
            </Button>
        </Container>
    );
};

const ResetIcon = ({ onClick }: { onClick: () => void }) => (
    <IconButton size="small" onClick={onClick} sx={{ position: "absolute", right: 24, top: 13 }}>
        <CloseIcon fontSize="small" />
    </IconButton>
);
