import { StepContentContainer } from "@dono-business/shared/components";
import { StepBackButton } from "@dono-business/shared/components/step-button-back/StepBackButton";
import { useAppRequests } from "@dono-business/shared/hooks";
import { BucksBalanceModel, PaymentMethodModel } from "@dono-business/shared/models";
import { KeyboardArrowRight } from "@mui/icons-material";
import {
    Box,
    capitalize,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { WideButton } from "./CreateGiftScreen.styles";
import { BillContainer } from "./Step3.styles";

type Step3Props = {
    rawAmount: number;
    onSelectedPaymentMethodChange?: (v: PaymentMethodModel) => void;
    shouldUseBucksBalance: boolean;
    setShouldUseBucksBalance: (shouldUse: boolean) => void;
    bucksBalance: BucksBalanceModel | null;
    onNext: () => void;
    onPrev: () => void;
};
export const Step3 = ({
    onNext,
    onPrev,
    rawAmount,
    onSelectedPaymentMethodChange,
    shouldUseBucksBalance,
    setShouldUseBucksBalance,
    bucksBalance,
}: Step3Props) => {
    const { giftRequests } = useAppRequests();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodModel[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodModel>();
    const [isUploading, setIsUploading] = useState(false);
    const amountToPay = rawAmount;
    useEffect(() => {
        if (amountToPay >= 0) {
            setIsUploading(true);
            giftRequests
                .getPaymentMethods(amountToPay, shouldUseBucksBalance)
                .then((res) => {
                    setPaymentMethods(res.data);
                    setSelectedPaymentMethod(res.data[0]);
                })
                .catch(() => {
                    setPaymentMethods([]);
                    setSelectedPaymentMethod(undefined);
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }
    }, [amountToPay, shouldUseBucksBalance, giftRequests]);

    const onPaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const spm = paymentMethods.find((paymentMethod) => paymentMethod.method === event.target.value);
        setSelectedPaymentMethod(spm);
    };

    useEffect(() => {
        if (selectedPaymentMethod) {
            onSelectedPaymentMethodChange?.(selectedPaymentMethod);
        }
    }, [selectedPaymentMethod, onSelectedPaymentMethodChange]);
    const billDetails = [
        { label: "Gift Subtotal", value: selectedPaymentMethod?.excludeFeeAmount?.value },
        { label: "Payment Method Fee", value: selectedPaymentMethod?.feeAmount?.value },
        {
            label: "DONO Bucks",
            value: selectedPaymentMethod?.bucksAmount?.value ?? 0,
            hidden: !shouldUseBucksBalance,
        },
        { label: "Transaction Fee", value: selectedPaymentMethod?.transactionFeeAmount?.value },
    ];
    const onUseBucksToggle = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
        setShouldUseBucksBalance?.(checked);

    const disablePaymentMethods = !selectedPaymentMethod?.includeFeeAmount?.value;
    const hasBucksBalance = !!bucksBalance?.availableBalance?.value;
    const shouldShowBucksOption = !!amountToPay && hasBucksBalance;

    return (
        <StepContentContainer isLoading={isUploading}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: "100%" }}>
                        <FormLabel id="payment-methods-radio-button" sx={{ mb: 2 }}>
                            <Typography variant="subTitle" sx={{ opacity: disablePaymentMethods ? 0.4 : 1 }}>
                                Choose payment method:
                            </Typography>
                        </FormLabel>
                        {shouldShowBucksOption && (
                            <FormControlLabel
                                control={<Checkbox checked={shouldUseBucksBalance} onChange={onUseBucksToggle} />}
                                sx={{ mb: 1 }}
                                label={
                                    <Typography
                                        sx={(theme) => ({
                                            color: theme.palette.background.freshLight,
                                            fontWeight: "700",
                                            fontSize: 16,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexGrow: 1,
                                        })}
                                    >
                                        <span>Use DONO Bucks</span>
                                        <span>
                                            {bucksBalance?.availableBalance?.symbol ?? ""}
                                            {bucksBalance?.availableBalance?.value ?? 0}
                                        </span>
                                    </Typography>
                                }
                            />
                        )}
                        {!!paymentMethods.length && (
                            <RadioGroup
                                aria-labelledby="payment-methods-radio-button"
                                value={selectedPaymentMethod?.method}
                                onChange={onPaymentMethodChange}
                                name="radio-buttons-group"
                            >
                                {paymentMethods
                                    ?.filter((pm) => pm?.method !== "applePay" && pm?.method !== "googlePay")
                                    .map((item) => (
                                        <FormControlLabel
                                            checked={
                                                !disablePaymentMethods && item.method === selectedPaymentMethod?.method
                                            }
                                            key={item.method}
                                            value={item.method}
                                            control={<Radio />}
                                            disabled={disablePaymentMethods}
                                            disableTypography
                                            label={
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    flexGrow={1}
                                                    sx={{ opacity: disablePaymentMethods ? 0.4 : 1 }}
                                                >
                                                    {capitalize(item.displayName)}
                                                    <Typography ml={1} variant="label">
                                                        {item.percent ? <>(Fee: {item.percent}%)</> : <>(No Fee)</>}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    ))}
                            </RadioGroup>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <BillContainer flex="1">
                        <Box display={"flex"} justifyContent="space-between">
                            <Box flex={1}>
                                {billDetails.map(
                                    (row) =>
                                        !row.hidden && (
                                            <Box mb={1} key={row.label}>
                                                <Typography
                                                    variant="caption"
                                                    sx={(theme) => ({
                                                        color:
                                                            row.label === "DONO Bucks"
                                                                ? theme.palette.text.fresh
                                                                : "initial",
                                                    })}
                                                >
                                                    {row.label}
                                                </Typography>
                                            </Box>
                                        ),
                                )}
                                <Divider sx={{ mt: 3, borderColor: (theme) => theme.palette.primary.light }} />
                                <Box mb={1} mt={3}>
                                    <Typography variant="h3">Total</Typography>
                                </Box>
                            </Box>
                            <Box flexBasis="20%">
                                {billDetails.map(
                                    (row) =>
                                        !row.hidden && (
                                            <Box mb={1} key={row.label}>
                                                <Typography
                                                    variant="caption"
                                                    sx={(theme) => ({
                                                        color:
                                                            row.label === "DONO Bucks"
                                                                ? theme.palette.text.fresh
                                                                : "initial",
                                                    })}
                                                >
                                                    ${row.value}
                                                </Typography>
                                            </Box>
                                        ),
                                )}
                                <Divider sx={{ mt: 3, borderColor: (theme) => theme.palette.primary.light }} />

                                <Box mb={1} mt={3}>
                                    <Typography variant="h3">
                                        ${selectedPaymentMethod?.includeFeeAmount?.value}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </BillContainer>
                </Grid>
            </Grid>
            <Box mt={2} display="flex" justifyContent="space-between">
                <StepBackButton onClick={onPrev} />

                <WideButton disabled={!selectedPaymentMethod} onClick={onNext} fullWidth={false}>
                    Next
                    <KeyboardArrowRight />
                </WideButton>
            </Box>
        </StepContentContainer>
    );
};
