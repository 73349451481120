import { Button, StepContentContainer } from "@dono-business/shared/components";
import { StepBackButton } from "@dono-business/shared/components/step-button-back/StepBackButton";
import { useAppRequests } from "@dono-business/shared/hooks";
import { GiftModel, PaymentMethodModel } from "@dono-business/shared/models";
import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { FileContent } from "use-file-picker/dist/interfaces";

const idealPersonalizeImageWidth = 328;
const idealPersonalizeImageHeight = 418;

type Step4Props = {
    title?: string;
    occasion?: string;
    message?: string;
    amount?: number;
    filesContent?: FileContent[];
    selectedPaymentMethod?: PaymentMethodModel;
    gifts: GiftModel[];
    giftContentType?: "photo" | "video";
    shouldUseBucksBalance: boolean;

    onPrev: () => void;
};
export const Step4 = ({
    onPrev,
    title,
    occasion,
    amount,
    filesContent,
    giftContentType,
    selectedPaymentMethod,
    shouldUseBucksBalance,
    gifts,
}: Step4Props) => {
    const { giftRequests } = useAppRequests();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = useCallback(() => {
        if (!selectedPaymentMethod || !gifts || !gifts.length) return;

        setIsSubmitting(true);
        const giftIds: number[] = gifts
            ?.filter((item) => item?.giftId && item?.giftId > 0)
            .map((item) => item.giftId) as number[];
        giftRequests
            .createStripeCheckoutSession(
                giftIds,
                selectedPaymentMethod?.method,
                window.location.origin + "/payment/successful",
                window.location.origin + "/payment/unsuccessful",
                shouldUseBucksBalance,
            )
            .then((res) => {
                if (res?.data?.amount?.value === 0) {
                    window.location.href = window.location.origin + "/payment/successful";
                } else {
                    if (res?.data?.url) {
                        window.location.href = res.data.url;
                    }
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [setIsSubmitting, gifts, selectedPaymentMethod, shouldUseBucksBalance, giftRequests]);

    return (
        <StepContentContainer>
            <Grid container justifyContent="space-between" gap={2} mb={{ xs: 5, md: 4.5 }}>
                <Grid item xs>
                    <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                        <Typography variant="subTitle">Title:</Typography>
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            {title}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                        <Typography variant="subTitle">Occasion:</Typography>
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            {occasion}
                        </Typography>
                    </Box>
                    {/* <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                        <Typography variant="subTitle">Send on:</Typography>
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            {scheduleDateTime ? new Date(scheduleDateTime).toLocaleString() : "Now"}
                        </Typography>
                    </Box> */}
                    {/* <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                        <Typography variant="subTitle">Message:</Typography>
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            {message || "-"}
                        </Typography>
                    </Box> */}
                    <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                        <Typography variant="subTitle">Recipients count:</Typography>
                        <Typography variant="caption" sx={{ ml: 1 }}>
                            {gifts?.length || "-"}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                        <Typography variant="h3">Total:</Typography>
                        <Typography variant="h3" sx={{ ml: 1 }}>
                            ${amount}
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    display="flex"
                    justifyContent={{ xs: "center", md: "end" }}
                    position="relative"
                    order={{ xs: -1, md: 0 }}
                >
                    {!!filesContent && !!filesContent.length && (
                        <Box
                            border={"1px solid #cbcbcb"}
                            borderRadius={2}
                            width={idealPersonalizeImageWidth}
                            height={idealPersonalizeImageHeight}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            overflow={"hidden"}
                        >
                            {filesContent?.map(
                                (file, index) =>
                                    (giftContentType === "photo" && (
                                        <img
                                            style={{
                                                height: "100%",
                                                maxWidth: "100%",
                                                objectFit: "contain",
                                            }}
                                            key={index}
                                            alt={file.name}
                                            src={file.content}
                                        />
                                    )) ||
                                    (giftContentType === "video" && (
                                        <video
                                            style={{
                                                height: "100%",
                                                maxWidth: "100%",
                                                objectFit: "contain",
                                            }}
                                            key={index}
                                            src={file.content}
                                            controls
                                        />
                                    )),
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Box mt={2} display="flex" justifyContent="space-between">
                <StepBackButton onClick={onPrev} />
                <Button
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    fullWidth={false}
                    sx={{ display: "flex", alignItems: "center", px: { xs: "25px", sm: "50px" }, py: "13px" }}
                >
                    Submit & Pay
                </Button>
            </Box>
        </StepContentContainer>
    );
};
