import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { Modal, TextField, OutlinedTextFieldProps, Card, Box, Button } from "@mui/material";
import { addDays, differenceInCalendarDays, endOfDay, isSameDay, startOfDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { DateRangePicker as RDRDateRangePicker, InputRange } from "react-date-range";

export interface DateRange {
    startDate?: Date;
    endDate?: Date;
    key?: string;
}

interface DateRangePickerProps extends OutlinedTextFieldProps {
    range?: DateRange;
    onRangeChange: (value: DateRange) => void;
}

export const DateRangePicker = ({ range, onRangeChange, ...props }: DateRangePickerProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [visibleRange, setVisibleRange] = useState<DateRange>({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });

    const toggle = () => setIsOpen((v) => !v);

    const stringValue = useMemo(() => {
        if (!range?.startDate || !range?.endDate) return "";

        const startDate = new Date(range?.startDate).toDateString();
        const endDate = new Date(range?.endDate).toDateString();
        return `${startDate} - ${endDate}`;
    }, [range]);

    useEffect(() => {
        if (range?.startDate && range?.endDate) {
            setVisibleRange(range);
        }
    }, [isOpen, setVisibleRange, range]);

    const defaultInputRanges: InputRange[] = [
        {
            label: "days up to today",
            range(value) {
                if (value) {
                    return {
                        startDate: addDays(startOfDay(new Date()), (Math.max(Number(value), 1) - 1) * -1),
                        endDate: endOfDay(new Date()),
                        disabled: false,
                    };
                }
                return {
                    startDate: new Date(),
                    endDate: new Date(),
                    disabled: true,
                };
            },
            // eslint-disable-next-line no-shadow
            getCurrentValue(range) {
                if (range.disabled) return "";
                if (!isSameDay(range.endDate ? range.endDate : new Date(), endOfDay(new Date()))) return "-";
                if (!range.startDate) return "∞";
                return differenceInCalendarDays(endOfDay(new Date()), range.startDate) + 1;
            },
        },
    ];
    return (
        <>
            <TextField
                InputProps={{ readOnly: true }}
                placeholder="All time"
                onClick={toggle}
                value={stringValue}
                autoComplete="off"
                {...props}
            />

            <Modal
                open={isOpen}
                onClose={toggle}
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <Card
                    sx={(theme) => ({
                        position: "absolute",
                        p: 4,
                        [theme.breakpoints.down("sm")]: {
                            p: 2,
                            "& .rdrDateRangePickerWrapper": {
                                flexDirection: "column",
                                width: "300px",
                            },
                            "& .rdrDefinedRangesWrapper": { width: "100%" },
                            "& .rdrStaticRanges": { flexDirection: "row", flexWrap: "wrap" },
                            "& .rdrStaticRange": { flexBasis: "50%" },
                        },
                    })}
                >
                    <RDRDateRangePicker
                        onChange={(item) => {
                            setVisibleRange(item.selection);
                        }}
                        showPreview
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={[visibleRange]}
                        inputRanges={defaultInputRanges}
                        direction="horizontal"
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={toggle} fullWidth={false} variant="outlined" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                toggle();
                                onRangeChange(visibleRange);
                            }}
                            fullWidth={false}
                        >
                            Ok
                        </Button>
                    </Box>
                </Card>
            </Modal>
        </>
    );
};
