import * as React from "react";
import { SVGProps } from "react";

export const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={55} height={42} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M55 0H0v42h55V0Z" fill="#fff" />
        <path
            d="M51.068 1H3.927a.752.752 0 0 0-.755.755v4.446c0 .168 5.7 6.711 5.7 6.711s9.73 2.181 17.2.168c9.562-2.6 18.957 1.091 18.957 1.091s6.795-7.718 6.795-7.97V1.756a.752.752 0 0 0-.755-.755L51.068 1Zm-43.7 3.355h-.755l-.084.839a.839.839 0 0 1-1.677 0v-.755a1.72 1.72 0 0 1 1.76-1.762h.756a.839.839 0 0 1 0 1.678Zm5.033 0h-1.678a.84.84 0 1 1 0-1.677H12.4a.839.839 0 1 1 0 1.677Z"
            fill="#B993C2"
        />
        <path
            d="M26.492 14.673a35.783 35.783 0 0 1-15.937.084l13.757 15.6a2.342 2.342 0 0 1 .67 1.678v8.724l5.034-2.516v-6.207a2.343 2.343 0 0 1 .67-1.678l13.086-14.763a29.613 29.613 0 0 0-17.28-.923Zm8.388 8.724-.755.839a.834.834 0 0 1-1.174.084.752.752 0 0 1-.084-1.174l.755-.84a.815.815 0 0 1 1.174-.083.881.881 0 0 1 .084 1.173Zm5.033-5.872-3.355 3.942c-.168.168-.755.587-1.174.084a.816.816 0 0 1-.084-1.174l3.355-3.942a.814.814 0 0 1 1.174-.084.881.881 0 0 1 .084 1.174Z"
            fill="#B993C2"
        />
    </svg>
);
