import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CustomDataGrid } from "@dono-business/shared/components";
import React from "react";

import { ReportGiftsFilter } from "./ReportGiftsFilter";
import { useReportGiftScreenHelper } from "./ReportGiftsScreen.helper";
import { ExportButton } from "@dono-business/shared/components";
import { useGridApiRef } from "@mui/x-data-grid";

export const ReportGiftsScreen = () => {
    const { title, giftsGridColumns, reportGifts, isLoading, params, setParams, isSmallScreen } =
        useReportGiftScreenHelper();
    const apiRef = useGridApiRef();
    return (
        <>
            {isSmallScreen && (
                <Box display={"grid"} gridTemplateColumns="repeat(3, minmax(0, 1fr))" mb={4}>
                    <Button
                        variant="text"
                        size="small"
                        fullWidth={false}
                        onClick={() => window.history.back()}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            lineHeight: "normal",
                        }}
                    >
                        <ChevronLeftIcon /> Back
                    </Button>
                    <Typography variant="screenTitle" justifySelf={"center"} display="flex" alignItems={"center"}>
                        Gifts Report
                    </Typography>
                </Box>
            )}
            {!isSmallScreen && (
                <Button
                    variant="text"
                    size="small"
                    fullWidth={false}
                    onClick={() => window.history.back()}
                    sx={{ mb: 5, display: "flex", alignItems: "center" }}
                >
                    <ChevronLeftIcon /> Back
                </Button>
            )}
            <ReportGiftsFilter preSelectedCampaignIds={params?.campaignIds} onFilterSubmit={setParams} />

            <Box sx={{ mt: 4, boxShadow: "none", position: "relative" }}>
                <Box mb={4} display={"flex"} justifyContent="space-between" alignItems="center">
                    <Typography variant="title">{title}</Typography>
                    {!isLoading && reportGifts && (
                        <ExportButton
                            onClick={() =>
                                apiRef.current.exportDataAsCsv({ fileName: `gift_events_${new Date().getTime()}` })
                            }
                        />
                    )}
                </Box>

                <CustomDataGrid
                    getRowId={(r) => r.giftId}
                    rows={reportGifts ?? []}
                    columns={giftsGridColumns}
                    loading={isLoading}
                    autoHeight
                    initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                    disableRowSelectionOnClick
                    apiRef={apiRef}
                />
            </Box>
        </>
    );
};
