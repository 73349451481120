import { useAppRequests } from "@dono-business/shared/hooks";
import { useEffect, useState } from "react";

export const useOccasionsList = () => {
    const { giftRequests } = useAppRequests();
    const [data, setData] = useState<string[] | null>(null);
    useEffect(() => {
        const fetch = () => {
            giftRequests
                .getAllOccasions()
                .then(({ data }) => setData(data))
                .catch(() => setData(null));
        };
        fetch();
    }, [giftRequests]);
    return data;
};
