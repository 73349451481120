import { Header } from "@dono-business/shared/components/header";
import { LayoutBody } from "@dono-business/shared/components/layout-body";
import { useMemo } from "react";

export const Layout = () => {
    const routes = useMemo(
        () => [
            { path: "/create-gift", title: "Create & Send Gift" },
            { path: "/report", title: "Reports" },
        ],
        [],
    );
    return (
        <>
            <Header routes={routes} />
            <LayoutBody />
        </>
    );
};
