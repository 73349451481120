import * as React from "react";
import { SVGProps } from "react";

export const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.242 11.375V14.54C15.242 14.9596 15.0753 15.362 14.7786 15.6586C14.482 15.9553 14.0796 16.122 13.66 16.122H2.582C2.16243 16.122 1.76004 15.9553 1.46336 15.6586C1.16667 15.362 1 14.9596 1 14.54V11.375"
            stroke="#B993C2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.16406 7.41797L8.12006 11.374L12.0761 7.41797"
            stroke="#B993C2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.11719 11.3739V1.87891"
            stroke="#B993C2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
