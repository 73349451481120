import React, { SVGProps } from "react";
export const StepThreeGrayIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="119" height="120" viewBox="0 0 119 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <g filter="url(#filter0_d_11_1195)">
                    <path
                        d="M91 14.0039H28C21.3726 14.0039 16 19.3765 16 26.0039V89.0039C16 95.6313 21.3726 101.004 28 101.004H91C97.6274 101.004 103 95.6313 103 89.0039V26.0039C103 19.3765 97.6274 14.0039 91 14.0039Z"
                        fill="url(#paint0_linear_11_1195)"
                    />
                    <path
                        d="M91 14.5039H28C21.6487 14.5039 16.5 19.6526 16.5 26.0039V89.0039C16.5 95.3552 21.6487 100.504 28 100.504H91C97.3513 100.504 102.5 95.3552 102.5 89.0039V26.0039C102.5 19.6526 97.3513 14.5039 91 14.5039Z"
                        stroke="white"
                        strokeOpacity="0.01"
                    />
                </g>
            </g>
            <path
                d="M43.484 54.12H45.592C46.952 54.12 48.2667 54.0067 49.536 53.78C50.8507 53.508 52.0067 53.0773 53.004 52.488C54.0013 51.8533 54.7947 51.0147 55.384 49.972C56.0187 48.9293 56.336 47.6373 56.336 46.096C56.336 44.872 56.1093 43.784 55.656 42.832C55.2027 41.8347 54.568 40.996 53.752 40.316C52.9813 39.636 52.0747 39.1147 51.032 38.752C50.0347 38.344 48.9693 38.14 47.836 38.14C45.796 38.14 44.0507 38.6613 42.6 39.704C41.1947 40.7467 40.1293 42.1973 39.404 44.056L33.42 41.948C34.5987 39.0467 36.48 36.78 39.064 35.148C41.6933 33.4707 44.708 32.632 48.108 32.632C50.1027 32.632 51.984 32.9267 53.752 33.516C55.5653 34.06 57.152 34.876 58.512 35.964C59.872 37.052 60.9373 38.412 61.708 40.044C62.524 41.676 62.932 43.5573 62.932 45.688C62.932 47.048 62.7053 48.3173 62.252 49.496C61.844 50.6747 61.2547 51.74 60.484 52.692C59.7587 53.644 58.8747 54.46 57.832 55.14C56.7893 55.82 55.656 56.3187 54.432 56.636V56.772C55.8373 57.044 57.1293 57.52 58.308 58.2C59.532 58.88 60.5747 59.7413 61.436 60.784C62.2973 61.7813 62.9773 62.9373 63.476 64.252C63.9747 65.5667 64.224 66.9947 64.224 68.536C64.224 70.9387 63.7707 73.0693 62.864 74.928C61.9573 76.7413 60.756 78.26 59.26 79.484C57.764 80.708 56.0413 81.6373 54.092 82.272C52.1427 82.9067 50.148 83.224 48.108 83.224C44.3 83.224 40.9453 82.3627 38.044 80.64C35.1427 78.9173 33.0347 76.2427 31.72 72.616L37.84 70.576C38.5653 72.6613 39.7893 74.3613 41.512 75.676C43.28 76.9907 45.4333 77.648 47.972 77.648C49.196 77.648 50.3747 77.4667 51.508 77.104C52.6867 76.696 53.7293 76.1067 54.636 75.336C55.5427 74.5653 56.268 73.6133 56.812 72.48C57.356 71.3467 57.628 70.0093 57.628 68.468C57.628 66.7907 57.2653 65.3853 56.54 64.252C55.8147 63.1187 54.8627 62.212 53.684 61.532C52.5507 60.8067 51.2587 60.308 49.808 60.036C48.3573 59.7187 46.9293 59.56 45.524 59.56H43.484V54.12Z"
                fill="#0C2145"
            />
            <path
                d="M63.4542 83.915H92.8682C93.8534 83.9139 94.798 83.5221 95.4946 82.8254C96.1913 82.1288 96.5831 81.1842 96.5842 80.199V62.216C96.5831 61.2308 96.1913 60.2862 95.4946 59.5896C94.798 58.8929 93.8534 58.5011 92.8682 58.5H63.4552C62.4705 58.5019 61.5267 58.894 60.8307 59.5906C60.1347 60.2872 59.7432 61.2313 59.7422 62.216V80.199C59.7432 81.1842 60.1351 82.1288 60.8317 82.8254C61.5284 83.5221 62.473 83.9139 63.4582 83.915H63.4542Z"
                fill="#D1D5DE"
            />
            <path
                d="M66.8637 64.1142H67.1487C67.3032 64.1142 67.4514 64.0529 67.5606 63.9436C67.6699 63.8344 67.7312 63.6862 67.7312 63.5317C67.7312 63.3772 67.6699 63.2291 67.5606 63.1198C67.4514 63.0106 67.3032 62.9492 67.1487 62.9492H66.8637C66.7093 62.9492 66.5611 63.0106 66.4518 63.1198C66.3426 63.2291 66.2813 63.3772 66.2812 63.5317C66.2813 63.6862 66.3426 63.8344 66.4518 63.9436C66.5611 64.0529 66.7093 64.1142 66.8637 64.1142Z"
                fill="#D1D5DE"
            />
            <path
                d="M67.4818 74.7383H65.2388C65.0843 74.7383 64.9361 74.7996 64.8269 74.9089C64.7176 75.0181 64.6562 75.1663 64.6562 75.3208C64.6562 75.4753 64.7176 75.6234 64.8269 75.7327C64.9361 75.8419 65.0843 75.9033 65.2388 75.9033H67.4818C67.6362 75.9033 67.7844 75.8419 67.8936 75.7327C68.0029 75.6234 68.0643 75.4753 68.0643 75.3208C68.0643 75.1663 68.0029 75.0181 67.8936 74.9089C67.7844 74.7996 67.6362 74.7383 67.4818 74.7383Z"
                fill="#D1D5DE"
            />
            <path
                d="M78.1635 74.7383H70.4185C70.264 74.7383 70.1158 74.7996 70.0066 74.9089C69.8973 75.0181 69.8359 75.1663 69.8359 75.3208C69.8359 75.4753 69.8973 75.6234 70.0066 75.7327C70.1158 75.8419 70.264 75.9033 70.4185 75.9033H78.1635C78.3179 75.9033 78.4661 75.8419 78.5753 75.7327C78.6846 75.6234 78.7459 75.4753 78.7459 75.3208C78.7459 75.1663 78.6846 75.0181 78.5753 74.9089C78.4661 74.7996 78.3179 74.7383 78.1635 74.7383Z"
                fill="#D1D5DE"
            />
            <path
                d="M63.4542 83.915H92.8682C93.8534 83.9139 94.798 83.5221 95.4946 82.8254C96.1913 82.1288 96.5831 81.1842 96.5842 80.199V62.216C96.5831 61.2308 96.1913 60.2862 95.4946 59.5896C94.798 58.8929 93.8534 58.5011 92.8682 58.5H63.4552C62.4705 58.5019 61.5267 58.894 60.8307 59.5906C60.1347 60.2872 59.7432 61.2313 59.7422 62.216V80.199C59.7432 81.1842 60.1351 82.1288 60.8317 82.8254C61.5284 83.5221 62.473 83.9139 63.4582 83.915H63.4542ZM63.4542 59.664H92.8682C93.5445 59.6648 94.1929 59.9338 94.6711 60.412C95.1494 60.8903 95.4184 61.5387 95.4192 62.215V62.95H69.1772C69.0227 62.95 68.8745 63.0114 68.7653 63.1206C68.656 63.2299 68.5947 63.378 68.5947 63.5325C68.5947 63.687 68.656 63.8351 68.7653 63.9444C68.8745 64.0536 69.0227 64.115 69.1772 64.115H95.4192V67.008H87.7552C87.6007 67.008 87.4525 67.0694 87.3433 67.1786C87.2341 67.2879 87.1727 67.436 87.1727 67.5905C87.1727 67.745 87.2341 67.8932 87.3433 68.0024C87.4525 68.1116 87.6007 68.173 87.7552 68.173H95.4192V80.199C95.4184 80.8753 95.1494 81.5237 94.6711 82.002C94.1929 82.4802 93.5445 82.7492 92.8682 82.75H63.4542C62.7779 82.7492 62.1295 82.4802 61.6512 82.002C61.173 81.5237 60.904 80.8753 60.9032 80.199V68.172H85.3152C85.4697 68.172 85.6178 68.1106 85.7271 68.0014C85.8363 67.8922 85.8977 67.744 85.8977 67.5895C85.8977 67.435 85.8363 67.2868 85.7271 67.1776C85.6178 67.0684 85.4697 67.007 85.3152 67.007H60.9032V64.116H64.6822C64.8367 64.116 64.9848 64.0546 65.0941 63.9454C65.2033 63.8361 65.2647 63.688 65.2647 63.5335C65.2647 63.379 65.2033 63.2309 65.0941 63.1216C64.9848 63.0124 64.8367 62.951 64.6822 62.951H60.9032V62.216C60.9037 61.5395 61.1726 60.8909 61.6509 60.4124C62.1291 59.934 62.7777 59.6648 63.4542 59.664Z"
                fill="white"
            />
            <path
                d="M66.8637 64.1142H67.1487C67.3032 64.1142 67.4514 64.0529 67.5606 63.9436C67.6699 63.8344 67.7312 63.6862 67.7312 63.5317C67.7312 63.3772 67.6699 63.2291 67.5606 63.1198C67.4514 63.0106 67.3032 62.9492 67.1487 62.9492H66.8637C66.7093 62.9492 66.5611 63.0106 66.4518 63.1198C66.3426 63.2291 66.2813 63.3772 66.2812 63.5317C66.2813 63.6862 66.3426 63.8344 66.4518 63.9436C66.5611 64.0529 66.7093 64.1142 66.8637 64.1142Z"
                fill="white"
            />
            <path
                d="M67.4818 74.7383H65.2388C65.0843 74.7383 64.9361 74.7996 64.8269 74.9089C64.7176 75.0181 64.6562 75.1663 64.6562 75.3208C64.6562 75.4753 64.7176 75.6234 64.8269 75.7327C64.9361 75.8419 65.0843 75.9033 65.2388 75.9033H67.4818C67.6362 75.9033 67.7844 75.8419 67.8936 75.7327C68.0029 75.6234 68.0643 75.4753 68.0643 75.3208C68.0643 75.1663 68.0029 75.0181 67.8936 74.9089C67.7844 74.7996 67.6362 74.7383 67.4818 74.7383Z"
                fill="white"
            />
            <path
                d="M78.1635 74.7383H70.4185C70.264 74.7383 70.1158 74.7996 70.0066 74.9089C69.8973 75.0181 69.8359 75.1663 69.8359 75.3208C69.8359 75.4753 69.8973 75.6234 70.0066 75.7327C70.1158 75.8419 70.264 75.9033 70.4185 75.9033H78.1635C78.3179 75.9033 78.4661 75.8419 78.5753 75.7327C78.6846 75.6234 78.7459 75.4753 78.7459 75.3208C78.7459 75.1663 78.6846 75.0181 78.5753 74.9089C78.4661 74.7996 78.3179 74.7383 78.1635 74.7383Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_11_1195"
                    x="0"
                    y="0.00390625"
                    width="119"
                    height="119"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.239216 0 0 0 0 0.121569 0 0 0 0 0.262745 0 0 0 0.2 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11_1195" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11_1195" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_11_1195"
                    x1="69.331"
                    y1="19.6589"
                    x2="9.73599"
                    y2="134.412"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C6DA" />
                    <stop offset="1" stopColor="#0C2145" />
                </linearGradient>
            </defs>
        </svg>
    );
};
