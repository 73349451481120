import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { AddReceipientFormFieldsModel, GiftModel, ParsedGiftModel } from "@dono-business/shared/models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFilePicker } from "use-file-picker";
import { formatToInternational } from "@dono-business/shared/utils";
import { DownloadIcon } from "@dono-business/shared/icons";
import { useForm } from "react-hook-form";

import { KeyboardArrowRight } from "@mui/icons-material";
import { WideButton } from "./CreateGiftScreen.styles";
import { v4 as uuidv4 } from "uuid";
import { ReceipientsGrid } from "./ReceipientsGrid";
import { AddReceipientForm } from "./AddReceipientForm";
import { StepBackButton } from "@dono-business/shared/components/step-button-back/StepBackButton";
import { Dayjs } from "dayjs";
import { StepContentContainer } from "@dono-business/shared/components";
import { useAppRequests } from "@dono-business/shared/hooks";

type Step2Props = {
    occasion?: string;
    scheduleDateTime?: Dayjs;
    message?: string;
    title?: string;
    amount?: number;
    giftContentType?: string;
    companyName: string;
    mediaId?: string;
    timezone: string;
    onNext: () => void;
    onPrev: () => void;
    onGiftsCreate: (v: GiftModel[]) => void;
};
export const Step2 = ({
    occasion,
    scheduleDateTime,
    message,
    mediaId,
    amount,
    companyName,
    giftContentType,
    title,
    timezone,
    onGiftsCreate,
    onNext,
    onPrev,
}: Step2Props) => {
    const { giftRequests } = useAppRequests();
    const [parsedGifts, setParsedGifts] = useState<(ParsedGiftModel & { uniqueId: string })[]>([]);
    const [gifts, setGifts] = useState<GiftModel[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState("");
    const [openFileSelector, { clear }] = useFilePicker({
        readAs: "DataURL",
        accept: [".xlsx", ".xls"],
        onFilesSuccessfulySelected: ({ plainFiles }) => {
            parseFiles(plainFiles);
        },
    });
    const parseFiles = useCallback(
        async (plainFiles: File[]) => {
            if (plainFiles.length) {
                setError("");
                setParsedGifts([]);
                setIsUploading(true);
                giftRequests
                    .parseBatchFile(plainFiles[0])
                    .then((response) => {
                        clear();
                        const _parsedGifts = response.data.map((pg) => {
                            const _pg = { ...pg, uniqueId: uuidv4() };
                            _pg.giftee.phoneNumber = formatToInternational(pg.giftee.phoneNumber);
                            _pg.dateDeliveryScheduled =
                                _pg.dateDeliveryScheduled ?? (scheduleDateTime && scheduleDateTime.toISOString()) ?? "";
                            _pg.amount = _pg.amount?.value ? _pg.amount : { value: amount ?? 0 };
                            return _pg;
                        });
                        setParsedGifts(_parsedGifts);
                    })
                    .catch((err) => {
                        setError(err.response.data.message);
                    })
                    .finally(() => {
                        setIsUploading(false);
                    });
            }
        },
        [amount, scheduleDateTime, clear, giftRequests],
    );

    const isFormVisible = useMemo(() => parsedGifts.length < 100, [parsedGifts.length]);

    const foundSomeErrors = useMemo(() => parsedGifts.some((g) => g.status === "error"), [parsedGifts]);

    const handleBatchCreate = async () => {
        if (amount === undefined || !occasion || !companyName) return;

        const createdGifts: GiftModel[] = [];
        const req: GiftModel[] = parsedGifts.map((parsedGift) => {
            const { FirstName = "", LastName = "", phoneNumber, email = "" } = parsedGift.giftee;
            return {
                campaignName: title,
                amount: {
                    currencyCode: "USD",
                    symbol: "$",
                    value: parsedGift?.amount?.value != null ? +parsedGift?.amount?.value : +amount,
                },
                giftType: "egift",
                giftee: {
                    phoneNumber,
                    email,
                },
                gifter: {
                    phone: localStorage.getItem("auth-phone") || "",
                },
                gifterName: companyName,
                gifteeName: `${FirstName} ${LastName}`,
                message: parsedGift.message || message,
                occasion,
                dateDeliveryScheduled:
                    parsedGift.dateDeliveryScheduled || (scheduleDateTime && scheduleDateTime.toISOString()),
                ...(mediaId && {
                    mediaContent: {
                        giftContentId: mediaId,
                        giftContentType,
                    },
                }),
            };
        });
        setIsUploading(true);
        giftRequests
            .createBatch(req)
            .then((res) => {
                res.data.forEach((createdGift, index) => {
                    parsedGifts[index].status = "success";
                    parsedGifts[index].giftId = createdGift.giftId;
                    parsedGifts[index].giftee = {
                        ...parsedGifts[index].giftee,
                        phoneNumber: formatToInternational(createdGift?.giftee?.phoneNumber ?? ""),
                    };
                    createdGifts.push(createdGift);
                });
            })
            .catch((res) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                res.response.data.forEach((createdGift: any, index: number) => {
                    if (createdGift.error) {
                        parsedGifts[index].status = "error";
                        parsedGifts[index].giftId = undefined;
                        setError(createdGift.error.message);
                    } else {
                        parsedGifts[index].status = "success";
                        parsedGifts[index].giftId = createdGift.giftId;
                        createdGifts.push(createdGift);
                    }
                });
            })
            .finally(() => {
                setParsedGifts([...parsedGifts]);
                setGifts(createdGifts);

                setTimeout(() => {
                    setIsUploading(false);
                    if (createdGifts.length === parsedGifts.length) {
                        onNext();
                    }
                }, 2000);
            });
    };

    const remove = useCallback((uniqueId: string) => {
        setParsedGifts((prevParsedGifts) => prevParsedGifts.filter((gift) => gift.uniqueId !== uniqueId));
        setError("");
    }, []);

    useEffect(() => {
        onGiftsCreate?.(gifts);
    }, [gifts, onGiftsCreate]);

    const { control, reset, setValue, handleSubmit } = useForm<AddReceipientFormFieldsModel>({
        defaultValues: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            amount: 0,
            message: "",
            email: "",
        },
    });

    const autofillForm = useCallback(
        (formValues: Partial<Pick<AddReceipientFormFieldsModel, "message" | "amount" | "deliveryTime">>) => {
            Object.entries(formValues).forEach(([field, value]) =>
                setValue(field as keyof AddReceipientFormFieldsModel, value),
            );
        },
        [setValue],
    );

    const resetForm = useCallback(() => {
        reset();
        autofillForm({ message, amount, deliveryTime: scheduleDateTime });
    }, [message, amount, scheduleDateTime, autofillForm, reset]);

    const addFormsToParsedGifts = useCallback(
        (data: AddReceipientFormFieldsModel) => {
            const {
                amount: formAmount,
                deliveryTime: formDeliveryTime,
                phoneNumber: formPhoneNumber,
                firstName: formFirstName,
                lastName: formLastName,
                message: formMessage,
                email: formEmail,
            } = data;
            setParsedGifts((prevParsedGifts) => [
                ...prevParsedGifts,
                {
                    amount: { value: formAmount },
                    dateDeliveryScheduled: (formDeliveryTime && formDeliveryTime.toISOString()) ?? "",
                    giftee: {
                        phoneNumber: formatToInternational(formPhoneNumber),
                        FirstName: formFirstName,
                        LastName: formLastName,
                        ...(formEmail && { email: formEmail }),
                    },
                    uniqueId: uuidv4(),
                    message: formMessage,
                },
            ]);
            resetForm();
        },
        [resetForm],
    );

    useEffect(() => {
        autofillForm({ message });
    }, [message, autofillForm]);
    useEffect(() => {
        autofillForm({ amount });
    }, [amount, autofillForm]);
    useEffect(() => {
        autofillForm({ deliveryTime: scheduleDateTime });
    }, [scheduleDateTime, autofillForm]);

    return (
        <StepContentContainer isLoading={isUploading}>
            <Grid container justifyContent="space-between" gap={2.5} mb={{ xs: 3.75, md: 6 }}>
                <Grid item md={7} xs={12} display="flex" flexDirection={"column"} gap={{ xs: 0.75, md: 2.5 }}>
                    <Typography variant="subTitle" sx={{ fontWeight: 400 }}>
                        Use our Excel template to upload all of your gift recipients and their respective gift values
                    </Typography>{" "}
                    <Box
                        component={"a"}
                        sx={{ display: "flex", gap: 0.75, alignItems: "center", width: "fit-content" }}
                        href="https://www.donocard.com/s/Bulk-Gifting-Template.xlsx"
                    >
                        <DownloadIcon />
                        <Typography variant="link" sx={{ textDecoration: "underline", fontSize: 16, fontWeight: 400 }}>
                            DONO Bulk Gifting Template
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={4} xs={12} display="flex" justifyContent={{ xs: "center", md: "flex-end" }}>
                    <Button
                        variant="outlined"
                        onClick={openFileSelector}
                        sx={{
                            fontWeight: 600,
                            whiteSpace: "nowrap",
                            display: "flex",
                            p: "13px 55px",
                            width: "auto",
                        }}
                    >
                        Upload Excel File
                    </Button>
                </Grid>
            </Grid>

            <Grid container overflow={"auto"}>
                <Grid container item component={Paper} sx={{ boxShadow: "none" }}>
                    <Grid xs item>
                        <ReceipientsGrid
                            rowData={parsedGifts}
                            onRowRemove={remove}
                            giftDefaultValues={{ amount, message, scheduleDateTime }}
                        />
                    </Grid>

                    {isFormVisible && (
                        <AddReceipientForm
                            onSubmit={handleSubmit(addFormsToParsedGifts)}
                            fieldControl={control}
                            datePickerTimezone={timezone}
                        />
                    )}
                </Grid>
            </Grid>
            <Box mt={2} display="flex" justifyContent="space-between">
                <StepBackButton onClick={onPrev} />

                <WideButton
                    disabled={foundSomeErrors || isUploading || !parsedGifts.length}
                    onClick={handleBatchCreate}
                    fullWidth={false}
                >
                    {error ? (
                        `${error} ${"Please upload another file or remove failed rows."}`
                    ) : (
                        <>
                            Next
                            <KeyboardArrowRight />
                        </>
                    )}
                </WideButton>
            </Box>
        </StepContentContainer>
    );
};
