import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FilterIcon } from "@dono-business/shared/icons";

export const Container = styled(Grid)(({ theme }) => ({
    border: `1px dashed ${theme.palette.background.freshLight}`,
    position: "relative",
    borderRadius: 15,
}));

export const StyledFilterIcon = styled(FilterIcon)({
    position: "absolute",
    top: -20,
});
