import { Typography, StepLabel, StepConnector, StepContent, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Label = styled(Typography)({
    fontSize: 24,
    fontWeight: 600,
});

export const StyledStepConnector = styled(StepConnector)(() => ({
    marginLeft: 60,

    "& .MuiStepConnector-line": {
        borderLeft: "3px solid #7E6484",
        height: 60,
    },

    "&.Mui-disabled .MuiStepConnector-line": {
        borderLeft: "3px solid #C2C6DA",
    },
}));

export const StyledStepContent = styled(StepContent)(({ theme }) => ({
    borderLeft: "none",
    marginLeft: 0,
    paddingLeft: 60,

    "& .MuiCollapse-wrapperInner": {
        border: "1px solid #B993C24D",
        borderRadius: "0 8px 8px 0",
        borderLeft: "3px dashed #7E6484",
        padding: "50px 40px",
    },

    [theme.breakpoints.down("sm")]: {
        padding: "0",

        "& .MuiCollapse-wrapperInner": {
            border: "1px solid #B993C24D",
            borderRadius: 8,

            padding: "30px 8px",
        },
    },
}));

export const StyledStepLabel = styled(StepLabel)(() => ({
    padding: 0,

    "& .MuiStepLabel-iconContainer": { paddingRight: 0 },
}));

export const WideButton = styled(Button)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: " 13px 40px 13px 50px",
    [theme.breakpoints.down("sm")]: {
        padding: "13px 15px 13px 25px",
    },
}));
