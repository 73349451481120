import { useAppRequests } from "@dono-business/shared/hooks";
import { CompanyGifteeModel } from "@dono-business/shared/models";
import { useCallback, useRef, useState } from "react";

export const useCompanyGiftees = () => {
    const { giftRequests } = useAppRequests();
    const [options, setOptions] = useState<CompanyGifteeModel[]>([]);
    const lastInputTime = useRef<number>();
    const lastSearchedInput = useRef<string>();
    const fetchOptions = useCallback(
        (input: string) => {
            giftRequests
                .getCompanyGifteesWithQuery(input)
                .then((res) => {
                    lastSearchedInput.current = input;
                    setOptions(res.data);
                })
                .catch(() => {
                    setOptions([]);
                });
        },
        [giftRequests],
    );
    const fetchOptionsWithDebounce = useCallback(
        (input: string | null) => {
            lastInputTime.current = Date.now();
            const inputTime = Date.now();
            if (!input || input.length < 2) setOptions([]);
            else
                setTimeout(() => {
                    if (lastInputTime?.current && lastInputTime.current <= inputTime) {
                        fetchOptions(input);
                    }
                }, 1000);
        },
        [fetchOptions],
    );

    return { options, fetchOptionsWithDebounce, fetchOptions, lastSearchedInput };
};
