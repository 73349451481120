import "./App.css";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Navigate, Route, Routes } from "react-router";
import {
    CreateGiftScreen,
    ReportGiftsScreen,
    ReportScreen,
    PaymentResultScreen,
    LoginScreen,
    EmailLinkLogin,
    BucksScreen,
} from "screens";
import { theme } from "@dono-business/shared/theme";
import { Layout } from "components/layout";

import { ProtectedRoute } from "@dono-business/shared/components/ProtectedRoute";
import { MuiDateLocalizationProvider } from "@dono-business/shared/components/MuiDateLocalizationProvider";
import * as requests from "api";
import { AppTimezoneProvider, AppRequestsProvider } from "@dono-business/shared/components";

function App() {
    return (
        <AppRequestsProvider requests={requests}>
            <AppTimezoneProvider>
                <MuiDateLocalizationProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <Layout />
                                    </ProtectedRoute>
                                }
                            >
                                <Route index element={<Navigate to={"/create-gift"} />} />
                                <Route path="create-gift" element={<CreateGiftScreen />} />
                                <Route path="report" element={<ReportScreen />} />
                                <Route path="report/campaigns" element={<ReportScreen />} />
                                <Route path="report/gifts" element={<ReportGiftsScreen />} />
                                <Route path="bucks" element={<BucksScreen />} />
                            </Route>
                            <Route
                                path="login"
                                element={<LoginScreen pageTitle="Bulk Gifting Login" redirectPath="/create-gift" />}
                            />
                            <Route path="login/authenticate" element={<EmailLinkLogin />} />

                            <Route path="payment/:result" element={<PaymentResultScreen />} />
                        </Routes>
                    </ThemeProvider>{" "}
                </MuiDateLocalizationProvider>
            </AppTimezoneProvider>
        </AppRequestsProvider>
    );
}

export default App;
