import { Box, ButtonBase, Menu, MenuItem, Theme, Tooltip, Typography } from "@mui/material";
import {
    DateCalendar,
    DateTimePicker,
    DateTimePickerProps,
    DigitalClock,
    MultiSectionDigitalClock,
    PickersLayout,
    PickersLayoutProps,
    usePickerLayout,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { forwardRef, MouseEvent, ReactElement, useRef, useState } from "react";
import { SystemStyleObject } from "@mui/system";
import { DateOrTimeViewWithMeridiem } from "@mui/x-date-pickers/internals/models";
import { getDatePickerTimezoneOptions, systemTimezoneValue } from "@dono-business/shared/utils";
type Props = {
    compact?: boolean;
    disableTimezonePicker?: boolean;
    dateTimePickerStyles?: SystemStyleObject<Theme>;
    timezonePickerStyles?: SystemStyleObject<Theme>;
    compactTimePicker?: boolean;
} & Omit<DateTimePickerProps<Dayjs>, "timezone"> &
    (
        | { controlledTimezone: true; timezone: string; onTimezoneChange: (timezone: string) => void }
        | { controlledTimezone: false }
    ) & {
        renderDateTimePicker?: (dateTimePicker: ReactElement) => ReactElement;
        renderTimezonePicker?: (timezonePicker: ReactElement) => ReactElement;
    };

export const DateTimePickerWithTimezone = forwardRef<HTMLDivElement, Props>(function DateTimePickerWithTimezone(
    props,
    ref,
) {
    const timezones = getDatePickerTimezoneOptions();
    const [_timezone, _setTimezone] = useState(systemTimezoneValue);
    const {
        compact,
        disableTimezonePicker,
        controlledTimezone,
        renderDateTimePicker,
        renderTimezonePicker,
        timezonePickerStyles,
        dateTimePickerStyles,
        compactTimePicker,
        ...datePickerProps
    } = props;
    const timezone = controlledTimezone ? props.timezone : _timezone;
    const handleTimezoneChange = (selectedTimezone: string) => {
        if (controlledTimezone) props.onTimezoneChange(selectedTimezone);
        else _setTimezone(selectedTimezone);
        handleTimezoneMenuClose();
    };
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isTimezoneMenuOpen = Boolean(anchorEl);
    const handleTimezoneIconClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleTimezoneMenuClose = () => {
        setAnchorEl(null);
    };

    const closestDateTimeValue = useRef<Dayjs | null>(null);
    const hasAccepted = useRef<boolean>(false);

    const getClosestDateAndTime = () => {
        let dateAndTime = dayjs();
        const interval = datePickerProps.minutesStep ?? 15;
        const minutes = dateAndTime.minute();

        dateAndTime = dateAndTime.set("m", (Math.floor(minutes / interval) + 1) * interval);
        dateAndTime = dateAndTime.set("s", 0);
        dateAndTime = dateAndTime.set("ms", 0);

        return dateAndTime;
    };

    const handleDateTimePickerOpen = () => {
        if (!datePickerProps.value) {
            const displayValue = getClosestDateAndTime();
            closestDateTimeValue.current = displayValue;
            handleDateTimePickerChange(displayValue);
            setTimeout(() => {
                const okButton = document.querySelector(".MuiDialogActions-root button");
                okButton?.addEventListener("click", handleDateTimePickerAccept);
            });
        }
    };
    const handleDateTimePickerClose = () => {
        if (hasAccepted.current === false) {
            if (closestDateTimeValue.current) {
                if (datePickerProps.value === closestDateTimeValue.current) {
                    datePickerProps.onChange?.(null, { validationError: null });
                }
            }
        } else hasAccepted.current = false;
        closestDateTimeValue.current = null;
    };
    const handleDateTimePickerAccept = () => {
        hasAccepted.current = true;
    };
    const handleDateTimePickerChange = (value: Dayjs | null) => {
        datePickerProps.onChange?.(value, { validationError: null });
    };

    const label = timezones?.find((option) => option.value === timezone)?.label;
    const dateTimePicker = (
        <DateTimePicker
            {...datePickerProps}
            onChange={handleDateTimePickerChange}
            onOpen={handleDateTimePickerOpen}
            onClose={handleDateTimePickerClose}
            onAccept={() => null}
            value={datePickerProps.value || null}
            ref={ref}
            timezone={timezone}
            sx={dateTimePickerStyles}
            slots={{
                layout: (props) => (
                    <CustomPickersLayout {...props} compactTimePicker={compactTimePicker} timezoneLabel={label} />
                ),
            }}
        />
    );
    const timezonePicker = (
        <>
            <Tooltip title="Change Timezone">
                <Box
                    className="timezone-icon-wrapper"
                    sx={(theme) => ({
                        border: "1px solid #cbcbcb",
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        height: "3.5rem",
                        ...(compact && {
                            height: "fit-content",
                            justifyContent: "center",
                            p: "2px",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                        }),
                        ...(disableTimezonePicker && { opacity: ".6", pointerEvents: "none" }),
                        ...timezonePickerStyles,
                    })}
                >
                    <ButtonBase
                        sx={{
                            height: "100%",
                            px: 0.5,
                        }}
                        id="timezone-icon-button"
                        aria-controls={isTimezoneMenuOpen ? "timezone-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={isTimezoneMenuOpen ? "true" : undefined}
                        onClick={handleTimezoneIconClick}
                        disabled={disableTimezonePicker}
                    >
                        {label}
                    </ButtonBase>
                </Box>
            </Tooltip>
            <Menu
                id="timezone-menu"
                aria-labelledby="timezone-icon-button"
                anchorEl={anchorEl}
                open={isTimezoneMenuOpen}
                onClose={handleTimezoneMenuClose}
            >
                {timezones.map((tz) => (
                    <MenuItem
                        key={tz.value}
                        value={tz.value}
                        onClick={() => handleTimezoneChange(tz.value)}
                        selected={tz.value === timezone}
                    >
                        {tz.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
    return renderDateTimePicker && renderTimezonePicker ? (
        <>
            {renderDateTimePicker(dateTimePicker)}
            {renderTimezonePicker(timezonePicker)}
        </>
    ) : (
        <Box display={"flex"} gap="1rem" position={"relative"} width={1}>
            {dateTimePicker}
            {timezonePicker}
        </Box>
    );
});

export const CustomPickersLayout = (
    props: PickersLayoutProps<Dayjs | null, Dayjs, DateOrTimeViewWithMeridiem> & {
        compactTimePicker?: boolean;
        timezoneLabel?: string;
    },
) => {
    const { compactTimePicker, timezoneLabel } = props;

    const { content } = usePickerLayout(props);

    const contentChildren =
        content &&
        typeof content === "object" &&
        "props" in content &&
        content.props &&
        typeof content.props === "object" &&
        content?.props?.children[0].props.children;

    const dateCalendarProps = contentChildren && contentChildren[0].props;
    const clockProps = contentChildren && contentChildren[1].props?.children[1]?.props;
    const { timeSteps, ...digitalClockProps } = clockProps;
    return (
        <>
            <PickersLayout<Dayjs | null, Dayjs, DateOrTimeViewWithMeridiem>
                {...props}
                sx={{
                    ".MuiPickersLayout-contentWrapper": {
                        pl: "40px",
                        pr: "30px",
                        pt: "40px",
                        pb: "32px",
                        flexDirection: "row",
                        gap: "43px",
                    },
                    ".MuiPickersLayout-actionBar": { px: "40px", pb: "40px" },
                }}
            >
                <Box display={"flex"} flexDirection="column">
                    <Typography sx={{ fontSize: "16px", color: "#777", mb: "13px" }}>Select a Date</Typography>
                    <DateCalendar
                        {...dateCalendarProps}
                        sx={{
                            borderRadius: "4px",
                            border: "1px solid rgba(194, 198, 218, 0.32)",
                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
                            width: "310px",
                            ".MuiPickersCalendarHeader-root": { mt: "32px" },
                            ".MuiPickersCalendarHeader-label": { fontWeight: "600" },
                            ".MuiDayCalendar-weekDayLabel": { fontSize: "10px", fontWeight: "500" },
                            ".MuiPickersDay-root": { fontSize: "14px", fontWeight: "400" },

                            "& .MuiDayCalendar-weekDayLabel, & .MuiPickersDay-root": {
                                width: "24px",
                                height: "24px",
                                margin: 0,
                            },
                            ".MuiPickersSlideTransition-root, .MuiDateCalendar-viewTransitionContainer": {
                                height: "260px",
                            },
                            "[role=row]": { gap: "15px", my: "8px" },
                        }}
                        dayOfWeekFormatter={getDayOfWeekLabel}
                    />
                </Box>
                <Box display={"flex"} flexDirection="column" height="370px">
                    <Typography sx={{ fontSize: "16px", color: "#777", mb: "8px" }}>Select a Time</Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>{`${timezoneLabel}`}</Typography>
                    <Box
                        flexGrow={1}
                        sx={{
                            flexGrow: 1,
                            pt: "18px",
                            overflow: "hidden",
                            ".MuiMultiSectionDigitalClock-root, .MuiDigitalClock-root": {
                                height: "100%",
                                maxHeight: "100%",
                            },
                            ".MuiDigitalClock-root": { scrollBehavior: "unset", paddingRight: "10px" },
                            // ".MuiDigitalClock-list": { height: "100%", overflow: "auto" },
                            ".MuiDigitalClock-item": {
                                color: "#868686",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 0,
                                width: "108px",
                                height: "30px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(206, 209, 225, 0.30)",
                                textAlign: "center",
                                "&:not(:first-of-type)": { marginTop: "16px" },
                            },
                            ".MuiDigitalClock-item.Mui-selected": {
                                color: (theme) => theme.palette.text.contrast,
                                backgroundColor: (theme) => theme.palette.background.active,
                                fontWeight: "500",
                            },
                        }}
                    >
                        {compactTimePicker ? (
                            <DigitalClock
                                {...digitalClockProps}
                                timeStep={digitalClockProps.minutesStep}
                                view="hours"
                                focusedView={"hours"}
                                views={["hours"]}
                            />
                        ) : (
                            <MultiSectionDigitalClock {...clockProps} />
                        )}
                    </Box>
                </Box>
            </PickersLayout>
        </>
    );
};
const getDayOfWeekLabel = (day: string) => {
    switch (day) {
        case "Su":
            return "SUN";
        case "Mo":
            return "MON";
        case "Tu":
            return "TUE";
        case "We":
            return "WED";
        case "Th":
            return "THU";
        case "Fr":
            return "FRI";
        case "Sa":
            return "SAT";
    }
};
